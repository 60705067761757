import React from "react";
import { SideNavbarHustler } from "../../../components/sidenavbar";
import { getCurrentUser } from "../../../service/authService";
import { DashboardContent, DashboardFlex, DashboardFlexMenu, DashboardNavbar, DashboardWrapper } from "../../dashboard/style";
import { ProfileBody } from "./profileBody";

const user = getCurrentUser();

export const Profile = () => {
    return (
        <DashboardWrapper>
            <SideNavbarHustler />
            <DashboardContent>
                <DashboardNavbar>
                    <DashboardFlex>
                        <DashboardFlexMenu>
                            <h1>Hello, {user['username']}</h1>
                        </DashboardFlexMenu>
                    </DashboardFlex>
                </DashboardNavbar>
                <ProfileBody />
            </DashboardContent>
        </DashboardWrapper>
    )
}