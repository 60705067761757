import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import { SubmitButton, WarningOrangeWrapper } from "../../../page/dashboardHustlers/profile/profileBody/style";
import { createTransaction } from "../../../service/transactionService";
import { getUser } from "../../../service/userService";
import { ModalWrapper } from "../auth/style";
import { SelectionFlex, SelectionRules, StatusWithdrawWrapper, WithdrawFooterFlex, WithdrawHeader, WithdrawSelection, WithdrawWrapper } from "./style";

export const WithdrawForm = ({ remainingBalance }) => {
    const [user, setUser] = useState({});
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);

    const fetchUser = async () => {
        try {
            const { status, data } = await getUser();
            if (status === 200) {
                setUser(data.data);
            }
        } catch (e) {

        }
    }

    useEffect(
        () => {
            fetchUser();
        }
        , []);
    return (
        <ModalWrapper>
            <WithdrawHeader>
                <h1>Tujuan Penarikan</h1>
            </WithdrawHeader>
            {user && user['payment_method'] !== "" && user['payment_name'] !== "" && user['payment_number'] !== "" ? <WithdrawWrapper style={{ paddingLeft: "12px", borderLeft: "2px solid #0a5f99" }}>
                <h1>{user['payment_method']} - {user['payment_number']}</h1>
                <span>{user['payment_name']}</span>
            </WithdrawWrapper> : <WithdrawWrapper>
                <WarningOrangeWrapper style={{ margin: "0" }}>
                    Isi data bankmu untuk dapat menarik saldo
                </WarningOrangeWrapper>
                <h3 onClick={() => window.location = "/dashboard/hustler/profile"}>Isi Data Bank</h3>
            </WithdrawWrapper>}
            <WithdrawWrapper>
                <h1 style={{ marginBottom: "12px" }}>Metode Penarikan</h1>
                <SelectionFlex>
                    <WithdrawSelection type="checkbox" style={{ borderRadius: "50%" }} checked={true} />
                    <label>Instan</label><StatusWithdrawWrapper>Proses Cepat</StatusWithdrawWrapper>
                </SelectionFlex>
                <SelectionRules>
                    <li>Layanan sampai dengan 24 jam</li>
                    <li>Biaya transfer 2500</li>
                </SelectionRules>
            </WithdrawWrapper>
            <WithdrawWrapper style={{ borderBottom: "1px solid #eee", paddingBottom: "1.5rem" }}>
                <h1>Saldo Kamu</h1>
                <h2><CurrencyFormat value={remainingBalance} displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp. '} /></h2>
            </WithdrawWrapper>
            <WithdrawWrapper>
                <h1>Penarikan Saldo</h1>
                <p>Minimal: 50.000</p>
                <CurrencyInput
                    defaultValue={50000}
                    decimalsLimit={2}
                    name="withdrawal"
                    className="withdrawal"
                    onValueChange={(value) => setWithdrawalAmount(value)}
                    prefix="Rp. "
                    groupSeparator="."
                    decimalSeparator=","
                />
                <WithdrawFooterFlex>
                    <SubmitButton onClick={async () => {
                        if (remainingBalance < withdrawalAmount) {
                            toast.error("Saldomu tidak mencukupi")
                        } else if (withdrawalAmount < 100000) {
                            toast.error("Minimal Withdraw Rp. 100.000")
                        } else {
                            try {
                                const { status } = await createTransaction({
                                    amount: parseInt(withdrawalAmount, 10),
                                    bank_destination: user['payment_method'],
                                    bank_name: user['payment_name'],
                                    bank_number: user['payment_number']
                                })
                                if (status === 200) {
                                    toast.success("Berhasil request tarik saldo");
                                    setTimeout(function () {
                                        window.location = window.location.href;
                                    }, 1500)
                                }
                            } catch (e) {

                            }
                        }
                    }}>
                        Tarik
                    </SubmitButton>
                </WithdrawFooterFlex>
            </WithdrawWrapper>
        </ModalWrapper>
    );
}