import styled from "styled-components";

export const HowWrapper = styled.div`
  padding: 4rem 0;
  background: #f9f9f9;
`;

export const HowContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

export const HowTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const HowTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 2rem;

  & > h1 {
    font-size: 34px;
    letter-spacing: -.5px;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  & > p {
    color: #6c6c6c !important;
    font-weight: 400;
    font-size: 16px;
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding: 0;
  }
`;

export const CardGrid = styled.div`
  display: grid;
  margin-top: 2rem;
  padding: 0 2rem;
  gap: 1rem;

  @media screen and (min-width: 1024px) {
    margin-top: 4rem;
    gap: 1rem;
    padding: 0;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;
export const CardWrapper = styled.div`
  border: 1px solid #ffd75e;
  padding: 20px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .01);
  cursor: pointer;
  transition: all .3s ease 0s;

  &:hover {
    box-shadow: 0 5px 12px 2px rgba(0, 0, 0, .05);
  }
`;

export const CardTopWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  & > img {
    width: 50px;
    height: 50px;
  }
`;

export const PremiumWrapper = styled.div`
  display: flex;
  gap: 8px;


  & > img {
    width: 20px;
    height: 20px;
  }
`;

export const CardTopDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  & > h1 {
    color: #000 !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3;
  }

  & > p {
    color: #333 !important;
    font-size: 14px;
    margin-bottom: .25rem;
    margin-top: .3rem;
    font-weight: 300;

    & > span {
      font-weight: 600;
      font-size: 13px;
    }
  }

  & > span {
    font-size: 13px;
  }
`;

export const CardFooter = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;

  & > span {
    font-size: 13px;
    font-weight: 500;
    margin-right: 4px;
    margin-bottom: 4px;

    & > span {
      color: #6c4cbe;
    }
  }
`;

export const CardFooterCenter = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const CardDaysLeft = styled.div`
  margin-top: 32px;
  font-weight: 500;
    font-size: 13px;

  & > span {
    color: #007456;
  }
`;

export const MoreButton = styled.button`
  background: ${({ bg }) => bg};
  border: 1px solid ${({ color }) => color};
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-self: flex-start;
  cursor: pointer;
  box-shadow: 0 3px 0 0 #e2e2e2;  
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    background: ${({ bghover }) => bghover};
    border-color: ${({ bgborder }) => bgborder};
  }

  & > span {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
`;