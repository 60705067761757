import { FormComponentWrapper, FormLabel } from "../style";
import { FormTextArea } from "./textAreaElements";

const TextArea = ({ name, label, error, type, isUsingLabel, ...rest }) => {
    return (
        <FormComponentWrapper>
            {isUsingLabel ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
            <FormTextArea id={name} name={name} type={type} error={error} {...rest} />
        </FormComponentWrapper>
    );
};

export default TextArea;
