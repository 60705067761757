import styled from "styled-components";

export const TableBodyImage = styled.img`
  border-radius: 10px;
  width: 85px;
`;

export const ClickableSpan = styled.span`
  cursor: pointer;
  color: darkblue;
  padding-bottom: 1px;
  border-bottom: 1px solid #333;
`;

export const TableTd = styled.td`
  & > p {
    font-size: 13px;
    margin: 0;
    line-height: 1.4;
    margin-top: 6px;
    font-family: 'Inter', sans-serif;
    word-wrap: break-word;
  }

  & > span {
    color: #ed0006;
  }

  & > h1 {
    font-size: 14px;
    margin: 0;
    color: #111;
    font-weight: 500;
    word-wrap: break-word;
  }
`;

export const TableTdHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  & > h1 {
    font-size: 14px;
    margin: 0;
    color: #111;
    font-weight: 500;
  }

`;