import React from 'react';
import bottomLogo from '../../assets/images/bottom-logo.png';
import {
    FooterBottom,
    FooterContainer,
    FooterFirst,
    FooterFlex,
    FooterHalf,
    FooterMenu,
    FooterSplitter,
    FooterWrapper
} from "./style";

const Footer = (props) => (
    <FooterWrapper>
        <FooterContainer>
            <FooterFlex>
                <FooterFirst>
                    <img src={bottomLogo} alt="" />
                    <p>GetHustlers membantumu mendapatkan penghasilan tambahan dan mencari para Freelancer dengan
                        mudah.</p>
                </FooterFirst>
                <FooterFirst>
                    <FooterSplitter>
                        <FooterHalf>
                            <h1>Tentang</h1>
                            <FooterMenu>
                                <li><a href="/how">Cara Kerja</a></li>
                                <li><a href="/faq">FAQ</a></li>
                                <li><a href="/privacy">Privacy</a></li>
                                <li><a href="/terms">Terms of Service</a></li>
                            </FooterMenu>
                        </FooterHalf>
                        <FooterHalf>
                            <h1>Bantuan</h1>
                            <FooterMenu>
                                <li><a href="mailto:support@hustlers.com">support@hustlers.com</a></li>
                                <li><a href="https://bykace.com">Join Telegram</a></li>
                            </FooterMenu>
                        </FooterHalf>
                    </FooterSplitter>
                </FooterFirst>
            </FooterFlex>
            <FooterBottom>
                <p>© 2023 Hustlers | All Rights Reserved</p>
            </FooterBottom>
        </FooterContainer>
    </FooterWrapper>
);

export default Footer;