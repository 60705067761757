import React from "react";
import { FormComponentWrapper, FormLabel, FormSelect } from "../style";

const Select = ({ error, label, name, options, ...rest }) => (
    <FormComponentWrapper>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <FormSelect error={error} id={name} name={name} {...rest}>
            <option value="" />
            {options.map((option) => (
                <option key={option.name} value={option.name}>
                    {option.name}
                </option>
            ))}
        </FormSelect>
    </FormComponentWrapper>
);

export default Select;
