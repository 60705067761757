import React from 'react';
import NewJobForm from "../../../form/newJob";
import { MenuHeaderFlex, MenuHeaderWrapper } from "../hustles/style";
import { SideNavigationBarClient } from '../sidenavbar';
import { DashboardContentWrapper } from "../style";
import { DashboardClientWrapper } from '../wrapper';

const NewJob = () => (
    <DashboardContentWrapper>
        <SideNavigationBarClient />
        <DashboardClientWrapper
            child={<>
                <MenuHeaderWrapper>
                    <MenuHeaderFlex>
                        <h1>Tambah Side Hustle</h1>
                    </MenuHeaderFlex>
                </MenuHeaderWrapper>
                <NewJobForm />
            </>}
        />
    </DashboardContentWrapper>
);

export default NewJob;