import React, { useContext } from "react";
import * as Ri from 'react-icons/ri';
import { DataContext } from "../../context";
import { FormComponentWrapper, FormInput, FormLabel, InputWrapper } from "../style";

const Input = ({ id, error, name, label, type, paddingLeft, ...rest }) => {
    const { isClosed, setIsClosed } = useContext(DataContext);
    return (
        <FormComponentWrapper>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputWrapper display={name === "password" ? "block" : "none"}>
                <FormInput id={id} name={name} type={name === "password" ? isClosed ? "password" : "text" : type} {...rest} error={error} paddingLeft={paddingLeft} />
                {/*{isOpen ? (*/}
                {/*    <EyeOpen onClick={setIsOpen}/>) : (*/}
                {/*    <EyeClosed onClick={setIsOpen}/>*/}
                {/*)}*/}
                {isClosed ? <Ri.RiEyeCloseLine onClick={setIsClosed} /> : <Ri.RiEye2Line onClick={setIsClosed} />}
            </InputWrapper>
            {error ? <span>{error}</span> : null}
        </FormComponentWrapper>
    );
};

export default Input;
