import { getAuthHeaders, getCurrentUser } from "./authService";
import httpService from "./httpService";

export async function createCommentRoom(body) {
    return httpService.post("/api/comments", body, getAuthHeaders());
}

export async function getComments() {
    const user = getCurrentUser();
    let url = "/api/comments/dashboard";
    if (user['is_client']) {
        url += `?is_client=1`
    } else {
        url += `?is_client=0`
    }
    return httpService.get(url, getAuthHeaders());
}

export async function getCommentDetails(uuid) {
    return httpService.get(`/api/comments/${uuid}`, getAuthHeaders());
}

export async function updateComment(body) {
    return httpService.put("/api/comments", body, getAuthHeaders());
}
