import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { TableTd } from "../../../../components/table/tableBody/tableBodyElements";
import { getTransactions } from "../../../../service/transactionService";
import { ColoringStatus, HustleStatus, MenuHeaderWrapper } from "../../../dashboard/hustles/style";
import { DashboardContentWrapper } from "../../../dashboard/style";
import { Card, CardBody, StyledTable } from "../../style";

export const TransactionHistories = () => {
    const [transactions, setTransactions] = useState([]);

    const fetchTransactions = async () => {
        try {
            const { status, data } = await getTransactions();
            if (status === 200) {
                setTransactions(data.data);
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        fetchTransactions();
    }, [])

    return (
        <DashboardContentWrapper>
            <MenuHeaderWrapper>
                <h1>Transaksi</h1>
            </MenuHeaderWrapper>
            <Card>
                <CardBody>
                    <StyledTable>
                        <colgroup>
                            <col style={{ width: "60px" }} />
                            <col style={{ width: "60px" }} />
                            <col style={{ width: "50px" }} />
                            <col style={{ width: "100px" }} />
                            <col style={{ width: "50px" }} />
                            <col style={{ width: "50px" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>Penarikan Pada</td>
                                <td>Jumlah</td>
                                <td>Bank</td>
                                <td>Nama</td>
                                <td>Norek</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((trans, index) => <tr key={index}>
                                <TableTd>
                                    <p>{new Date(trans['created_at']).toLocaleDateString()}</p>
                                </TableTd>
                                <TableTd>
                                    <h1><CurrencyFormat value={trans['amount']} displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'Rp. '} /></h1>
                                    <p>Fee: <CurrencyFormat value={trans['fee']} displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'Rp. '} /></p>
                                </TableTd>
                                <TableTd>
                                    <p>{trans['bank_destination']}</p>
                                </TableTd>
                                <TableTd>
                                    <p>{trans['bank_name']}</p>
                                </TableTd>
                                <TableTd>
                                    <p>{trans['bank_number']}</p>
                                </TableTd>
                                <TableTd>
                                    <HustleStatus bg={ColoringStatus.get(trans['applier_status']).get('background')}
                                        color={ColoringStatus.get(trans['applier_status']).get('color')}>
                                        {trans['applier_status']}
                                    </HustleStatus>
                                </TableTd>
                            </tr>)}
                        </tbody>
                    </StyledTable>
                </CardBody>
            </Card>
        </DashboardContentWrapper>
    )
}