import Joi from "joi-browser";
import React from 'react';
import { toast } from "react-toastify";
import { DataContext } from "../../components/context";
import Form from "../../components/form";
import { register } from "../../service/authService";
import { NotesWrapper, OptionWrapper } from "./style";

class RegisterForm extends Form {
    static contextType = DataContext;

    state = {
        data: {},
        errors: {},
        onSubmit: false,
        type: ""
    }

    schema = {
        username: Joi.string().required().label("Username"),
        email: Joi.string().required().label("Email"),
        password: Joi.string().required().min(6).label("Kata Sandi"),
        full_name: Joi.string().required().label("Nama Lengkap"),
        phone_number: Joi.string()
            .trim()
            .regex(/^(\+62|62|0)8[1-9][0-9]{6,12}$/)
            .required().label("No Handphone")
    }

    onRadioChange = ({ currentTarget: input }) => {
        this.setState({ type: input.value });
    }

    doSubmit = async () => {
        const { setEmail, setIsRegistering } = this.context;
        const { data, onSubmit, type } = this.state;

        if (type === '') {
            alert("Pilih Tipe Akun");
            return;
        }

        if (!onSubmit) {
            this.setState({ onSubmit: true });
            try {
                const body = { ...data };
                body['type'] = type;
                const { status } = await register(body);
                if (status === 200) {
                    setEmail(data.email);
                    setIsRegistering(true);
                }
            } catch (e) {
                if (e.response && e.response.status === 400) {
                    toast.error(e.response.data.message);
                    this.setState({ onSubmit: false });
                }
            }
        } else {
            toast.warning("Mohon menunggu ...");
        }

    }

    render() {
        const { isClosed } = this.context;
        return (
            <form onSubmit={this.handleSubmit}>
                {this.renderInput("username", "Username*")}
                {this.renderInput("full_name", "Nama Lengkap*")}
                {this.renderInput("email", "Email*")}
                {this.renderInput("password", "Kata Sandi*", "", isClosed ? "password" : "text")}
                {this.renderInput("phone_number", "No Handphone*", "+62")}
                <OptionWrapper>
                    <input type="radio" name="type" id="hustler" onChange={this.onRadioChange} value={"hustler"} />
                    <label htmlFor="hustler">Saya adalah Hustler/Freelancer</label>
                    <input type="radio" name="type" id="seeker" onChange={this.onRadioChange} value={"seeker"} />
                    <label htmlFor="seeker">Saya adalah Seeker/Client</label>
                </OptionWrapper>
                <NotesWrapper>
                    <span>Hustler adalah pencari kerja sampingan, Seeker adalah penyedia projek atau pencari Hustler.</span>
                </NotesWrapper>
                {this.renderButton("Daftar")}
            </form>
        );
    }
}

export default RegisterForm;