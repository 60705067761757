import styled from "styled-components";

export const AuthForgotWrapper = styled.div`
  margin-top: 1rem;

  & > p > span {
    color: #007456;
  }

  & > p {
    font-size: 13px;
  }
`;

export const AuthCentered = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;

  & > p > span {
    color: #007456;
    cursor: pointer;
    transition: .5s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  & > p {
    font-size: 13px;
  }
`;