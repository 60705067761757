import React, { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination";
import { TableTd } from "../../../../components/table/tableBody/tableBodyElements";
import { getOrders } from "../../../../service/orderService";
import { paginate } from "../../../../utils";
import { StyledTable } from "../../../dashboardHustlers/style";
import { ColoringStatus, HustleStatus, MenuHeaderWrapper } from "../../hustles/style";
import { SideNavigationBarClient } from "../../sidenavbar";
import { DashboardContentWrapper } from "../../style";
import { DashboardClientWrapper } from "../../wrapper";

export const OrderPage = () => {
    const [data, setData] = useState([]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    useEffect(() => {
        fetchOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchOrders = async () => {
        try {
            const { status, data } = await getOrders();
            if (status === 200) {
                setData(data.data);
                setFilteredData(data.data);
                const paginated = paginate(data.data, currentPage, pageSize);
                setPaginatedData(paginated);
            }
        } catch (e) {

        }
    }
    return (
        <DashboardContentWrapper>
            <SideNavigationBarClient />
            <DashboardClientWrapper child={<>
                <MenuHeaderWrapper>
                    <h1>Order</h1>
                </MenuHeaderWrapper>
                <StyledTable>
                    <colgroup>
                        <col style={{ width: "150px" }} />
                        <col style={{ width: "120px" }} />
                        <col style={{ width: "130px" }} />
                        <col style={{ width: "130px" }} />
                        <col style={{ width: "100px" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Nama</th>
                            <th>Status</th>
                            <th>Invoice URL</th>
                            <th>Payment URL</th>
                            <th>Expired</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((data, index) => <tr key={index}>
                            <TableTd>
                                <h1>{data['project_name']}</h1>
                            </TableTd>
                            <TableTd>
                                <HustleStatus bg={ColoringStatus.get(data['project_status']).get('background')}
                                    color={ColoringStatus.get(data['project_status']).get('color')}>
                                    {data['project_status']}
                                </HustleStatus>
                                <p>Dibuat pada: {new Date(data['created_at']).toLocaleDateString()}</p>
                            </TableTd>
                            <TableTd>
                                <a href={data['payment_url']} target="_blank" rel="noreferrer">Link Bayar</a>
                            </TableTd>
                            <TableTd>
                                <a href={data['invoice_url']} target="_blank" rel="noreferrer">Link Invoice</a>
                            </TableTd>
                            <TableTd>
                                <p> {new Date(data['expired_at'] * 1000).toLocaleDateString()}</p>
                            </TableTd>
                        </tr>)}
                    </tbody>
                </StyledTable>
                <div style={{ marginTop: "1rem" }}>
                    <Pagination
                        itemsCount={filteredData.length}
                        onPageChange={(page) => {
                            setCurrentPage(page);
                            const paginated = paginate(data, page, pageSize)
                            setPaginatedData(paginated);
                        }}
                    />
                </div>
            </>} />
        </DashboardContentWrapper>
    )
}