import styled from "styled-components";

export const FormTextArea = styled.textarea`
  border: 1px solid #d1d1d1;
  height: ${({ height }) => (height ? height : "120px")};
  display: block;
  font-size: 13px;
  padding: 8px 1rem 20px;
  outline: none;
  width: 100%;
  text-align: justify;
  background: #fff;
  resize: vertical;
  max-height: 10rem;
  border-radius: 4px;

  &:active,
  &:focus {
    border: 1px solid #007456;
    outline: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
