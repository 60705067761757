export const Category = [
    {
        value: "Customer Service",
        label: "Customer Service"
    },
    {
        value: "Designer",
        label: "Designer"
    },
    {
        value: "Developer",
        label: "Developer"
    },
    {
        value: "Product Manager",
        label: "Product Manager"
    },
    {
        value: "Penulis atau Penerjemah",
        label: "Penulis atau Penerjemah"
    },
    {
        value: "Mahasiswa",
        label: "Mahasiswa"
    },
    {
        value: "Admin",
        label: "Admin"
    },
    {
        value: "Ibu Rumah Tangga",
        label: "Ibu Rumah Tangga"
    }
]