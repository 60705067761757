import Joi from "joi-browser";
import React from 'react';
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import featured from '../../assets/icons/dashboard/table/icon-featured.svg';
import urgent from '../../assets/icons/dashboard/table/icon-urgent.svg';
import email from '../../assets/images/email.png';
import Form from "../../components/form";
import RichText from "../../components/form/richtexteditor";
import { FormComponentWrapper, FormLabel, InputWrapper } from "../../components/form/style";
import { ModalComponent } from "../../components/modal";
import { ModalWrapper } from "../../components/modal/auth/style";
import { uploadImage } from "../../service/gcloudservice";
import { createJob } from "../../service/jobService";
import { CategoriesJob } from "./data";
import { FileContainer, FormBlockWrapper, FormFlexContainer, FormLabelWrapper, ServicePremWrapper } from "./style";

class NewJobForm extends Form {
    state = {
        data: {},
        errors: {},
        is_featured: false,
        urgent: false,
        files: [],
        objectURLs: [],
        showModal: false,
    }

    schema = {
        title: Joi.string(),
        category: Joi.string(),
        description: Joi.string(),
        quantity: Joi.number().min(1),
        bid: Joi.number().min(-1),
        deadline: Joi.string().required(),
        skill: Joi.string().required(),
        file_urls: Joi.string(),
    }

    setDescription = (description) => {
        const { data } = this.state;
        data["description"] = description;
        this.setState({ data });
    }

    setSkills = (skill) => {
        const { data } = this.state;
        data["skill"] = skill;
        this.setState({ data });
    }

    setFeatured = ({ currentTarget: input }) => {
        const is_featured = input.checked;
        this.setState({ is_featured })
    }

    setUrgent = ({ currentTarget: input }) => {
        const urgent = input.checked;
        this.setState({ urgent })
    }

    setShowModal = () => {
        const { showModal } = this.state
        this.setState({ showModal: !showModal });
    }


    doSubmit = async () => {
        const { data, is_featured, urgent, files } = this.state;

        const urls = [];
        for (const file of files) {
            const { status, data } = await uploadImage(file, "hustles");
            if (status === 200) {
                const fileURL = data.data["pathname"];
                urls.push(fileURL);
            }
        }

        try {
            const body = data;
            body["is_featured"] = is_featured ? 1 : 0;
            body["is_urgent"] = urgent ? 1 : 0;
            body["file_urls"] = urls;
            const { status } = await createJob(body);
            if (status === 200) {
                toast.success('Berhasil membuat side hustle!');
                if (is_featured) {
                    this.setShowModal();
                }
                setTimeout(function () {
                    window.location = "/dashboard/client/hustles"
                }, 1000)
            }
        } catch (e) {

        }
    }

    render() {
        const { files, showModal } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <FormBlockWrapper>
                    <h1 onClick={this.setShowModal}>Info Dasar</h1>
                    {this.renderInput("title", "Title Pekerjaan", "e.g: Membutuhkan desain logo branding")}
                    <FormFlexContainer>
                        {this.renderSelect("category", CategoriesJob.filter(category => category.name !== "Semua"), "Kategori")}
                        {this.renderInput("quantity", "Jumlah Hustlers", "", "number")}
                    </FormFlexContainer>
                    <FormLabel>Deskripsi</FormLabel>
                    <RichText setContent={this.setDescription} />
                </FormBlockWrapper>
                <FormBlockWrapper>
                    <h1>Detail Benefit</h1>
                    <FormFlexContainer>
                        <FormComponentWrapper>
                            <FormLabel>
                                Bid
                            </FormLabel>
                            <InputWrapper>
                                <CurrencyInput
                                    decimalsLimit={2}
                                    name="offer"
                                    className="offer"
                                    groupSeparator="."
                                    decimalSeparator=","
                                    onValueChange={(value) => {
                                        const { data } = this.state;
                                        data["bid"] = parseInt(value, 10);
                                        this.setState({ data });
                                    }}
                                    prefix="Rp. "
                                    allowNegativeValue={true}
                                    placeholder="Jika nominal bid berdasarkan penawaran hustlers, isi dengan -1"
                                />
                            </InputWrapper>
                        </FormComponentWrapper>

                        {this.renderInput("deadline", "Batas Akhir", "", "date")}
                    </FormFlexContainer>
                    <FormLabelWrapper>
                        <FormLabel style={{ marginBottom: "4px" }}>Skill</FormLabel>
                        <span>Jelaskan skill apa saja yang diperlukan bagi para Hustler untuk mengajukan tawaran di pekerjaan ini</span>
                    </FormLabelWrapper>
                    <RichText setContent={this.setSkills} />
                </FormBlockWrapper>
                <FormBlockWrapper>
                    <h1>File Pendukung</h1>
                    {this.renderInputFile("file_urls", "", "file", true, "image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, application/msword")}
                    {files.length > 0 && <FileContainer>
                        {files.map(file => <span>
                            {file.name}
                        </span>)}
                    </FileContainer>}
                </FormBlockWrapper>
                <FormBlockWrapper>
                    <h1>Layanan Tambahan</h1>
                    <ServicePremWrapper>
                        <input type="checkbox" name="is_featured" id="is_featured" onChange={this.setFeatured} />
                        <label htmlFor="vehicle1">Premium (Project kamu akan selalu muncul
                            paling atas selama 30
                            hari) dengan tambahan <span>IDR. 15.000</span> <img src={featured} alt="" /></label>
                    </ServicePremWrapper>
                    <ServicePremWrapper>
                        <input type="checkbox" name="urgent" id="urgent" onChange={this.setUrgent} />
                        <label htmlFor="vehicle1">Dibutuhkan Segera <img src={urgent} alt="" /></label>
                    </ServicePremWrapper>
                    {this.renderButton('Submit')}
                </FormBlockWrapper>
                <ModalComponent
                    show={showModal}
                    onHide={this.setShowModal}
                    body={<ModalWrapper>
                        <img src={email} alt="email" />
                        <p>Link pembayaran untuk hustle premium yang kamu buat sudah dikirimkan ke email ya. Segera lakukan pembayaran sebelum besok agar kami bisa segera verifikasi. Terima kasih!</p>
                    </ModalWrapper>}
                />
            </form>
        );
    }
}

export default NewJobForm;