import Joi from "joi-browser";
import React from 'react';
import CurrencyFormat from "react-currency-format";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import Form from "../../components/form";
import {
    BidBlockWrapper,
    BidDetail,
    BidDetailContainer,
    BidDetailWrapper,
    BidWrapper,
    IDRWrapper
} from "../../components/modal/applyJob/style";
import { uploadImage } from '../../service/gcloudservice';
import { applyJob } from "../../service/jobService";
import { FileContainer } from '../newJob/style';

class ApplyJobForm extends Form {
    state = {
        data: {},
        errors: {},
        files: [],
        objectURLs: [],
    }

    schema = {
        reason: Joi.string().required(),
        bid: Joi.number().min(0).required(),
        portfolio: Joi.string().required(),
        file_urls: Joi.string()
    }

    onBidChange = (value) => {
        const { data } = this.state;
        data["bid"] = value;
        this.setState({ data })
    }

    doSubmit = async () => {
        const { data, files } = this.state;
        const projectId = this.props.id;

        const urls = [];
        for (const file of files) {
            const { status, data } = await uploadImage(file, "applies");
            if (status === 200) {
                const fileURL = data.data["pathname"];
                urls.push(fileURL);
            }
        }

        let body = {
            project_id: projectId,
            bid: parseInt(data.bid, 10),
            description: data.reason,
            portfolio: data.portfolio,
            file_urls: urls
        }
        try {
            const { status } = await applyJob(body);
            if (status === 200) {
                toast.success('Berhasil apply');
                setTimeout(function () {
                    window.location = window.location.href;
                }, 500)
            }
        } catch (e) {

        }
    }


    render() {
        const { data, files } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <label style={{ marginTop: "1.5rem", marginBottom: "8px", fontSize: "14px", fontWeight: "700", fontFamily: "Plus Jakarta Sans, sans-serif" }} htmlFor="">Penawaran</label>
                <BidWrapper>
                    <IDRWrapper>
                        IDR
                    </IDRWrapper>
                    <CurrencyInput
                        defaultValue={50000}
                        decimalsLimit={2}
                        name="offer"
                        className="offer"
                        onValueChange={(value, name) => this.onBidChange(value)}
                        groupSeparator="."
                        decimalSeparator=","
                    />
                </BidWrapper>
                <BidDetailWrapper>
                    <BidDetailContainer>
                        <BidDetail>
                            <h1>Nilai Hustle</h1>
                            <span>{data['bid'] ? (
                                <CurrencyFormat value={(data['bid'] * 1.0).toFixed(0)} displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'Rp. '} />) : 0}</span>
                        </BidDetail>
                        <BidDetail>
                            <h1>Komisi 7%</h1>
                            <span>{data['bid'] ? (
                                <CurrencyFormat value={(data['bid'] * 0.07).toFixed(0)} displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'Rp. '} />) : 0}</span>
                        </BidDetail>
                        <BidDetail>
                            <h1>Pendapatan Anda</h1>
                            <span>{data['bid'] ? (
                                <CurrencyFormat value={(data['bid'] - (data['bid'] * 0.07)).toFixed(0)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'Rp. '} />) : 0}</span>
                        </BidDetail>
                    </BidDetailContainer>
                </BidDetailWrapper>
                {/*{this.renderInput("bid", "Tawaran (IDR)", "", "number")}*/}
                {this.renderTextArea("reason", "Alasan", "")}
                {this.renderInput("portfolio", "Portofolio", "Bisa berupa link, website, drive")}
                <BidBlockWrapper style={{ padding: "0", marginTop: "2rem" }}>
                    <h1>File Pendukung</h1>
                    <span>Seperti portfolio, cv, atau file tambahan</span>
                    {this.renderInputFile("file_urls", "", "file", true, "image/*,application/pdf")}
                    {files.length > 0 && <FileContainer>
                        {files.map(file => <span>
                            {file.name}
                        </span>)}
                    </FileContainer>}
                </BidBlockWrapper>
                {this.renderButton("Lamar")}
            </form>
        );
    }
}

export default ApplyJobForm;