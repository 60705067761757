import styled from "styled-components";

export const OptionWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 1rem;

  & > label {
    font-size: 13px;
  }
`;

export const NotesWrapper = styled.div`
  & > span {
    font-size: 12px;
    font-weight: 300;
    color: #918080;
  }
`;