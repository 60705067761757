import { RouterProvider, createBrowserRouter, redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import DataProvider from "./components/context";
import { ActivationPage } from "./page/activation";
import Applicants from "./page/dashboard/applicants";
import { Comments } from "./page/dashboard/comments";
import Hustles from "./page/dashboard/hustles";
import NewJob from "./page/dashboard/newJob";
import { OrderPage } from "./page/dashboard/orders/orderPage";
import { ProfileClient } from "./page/dashboard/profile";
import DashboardHustler from "./page/dashboardHustlers";
import { Transactions } from "./page/dashboardHustlers/balance";
import { CommentPage } from "./page/dashboardHustlers/comments";
import { Profile } from "./page/dashboardHustlers/profile";
import JobDetail from "./page/job_detail";
import Jobs from "./page/jobs";
import Main from "./page/main";
import RegisterHustler from "./page/register-hustler";
import TermsCondition from "./page/tnc";
import { getCurrentUser } from './service/authService';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        errorElement: <div><h1>Page Not Found</h1></div>,
    },
    {
        path: "/hustles",
        element: <Jobs />
    },
    {
        path: "/activate",
        element: <ActivationPage />
    },
    {
        path: "/hustle/:uuid",
        element: <JobDetail />
    },
    {
        path: "/register",
        element: <RegisterHustler />,
        loader: () => {
            const user = getCurrentUser();
            if (user) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/terms",
        element: <TermsCondition />
    },
    {
        path: "/dashboard/hustler/profile",
        element: <Profile />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/dashboard/hustler/hustles",
        element: <DashboardHustler />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/dashboard/hustler/transactions",
        element: <Transactions />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/dashboard/hustler/message",
        element: <CommentPage />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/dashboard/client/orders",
        element: <OrderPage />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || !user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/dashboard/client/applicants",
        element: <Applicants />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || !user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/dashboard/client/hustles",
        element: <Hustles />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || !user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/dashboard/client/hustles/new",
        element: <NewJob />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || !user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/dashboard/client/message",
        element: <Comments />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || !user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
    {
        path: "/dashboard/client/profile",
        element: <ProfileClient />,
        loader: () => {
            const user = getCurrentUser();
            if (!user || !user['is_client']) {
                return redirect("/");
            }
            return null;
        }
    },
])

function App() {
    return (
        <DataProvider>
            <ToastContainer />
            <RouterProvider router={router} />
            {/* <Switch>
                <ProtectedHustlerRoute path="/dashboard/hustlers" component={DashboardHustler}/>
                <ProtectedClientRoute path="/dashboard" component={Dashboard}/>
                <Route path="/hustles" component={Jobs}/>
                <Route path="/activate" component={Activation}/>
                <Route path="/register" component={RegisterHustler}/>
                <Route path="/syarat-ketentuan" component={TnC}/>
                <Route path="/hustle/:uuid" component={JobDetail}/>
                <Route path="/" component={Main}/>
                <Redirect to="/"/>
            </Switch> */}
        </DataProvider>
    );
}

export default App;
