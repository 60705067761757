import React, { useState } from 'react';
import { ModalComponent } from '../../components/modal';
import AuthBody from '../../components/modal/auth';
import { getCurrentUser } from '../../service/authService';
import { HeroButton, HeroButtonWrapper } from "../hero/style";
import { HookContainer, HookDetail, HookTop, HookWrapper } from "./style";

const user = getCurrentUser();

const Hook = (props) => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <HookWrapper>
            <HookContainer>
                <HookDetail>
                    <HookTop>
                        <h1>Hustlers, Platform Cari Penghasilan Tambahan yang Mudah</h1>
                        <p>Kamu mahasiswa, pekerja lepas, atau pekerja profesional, temukan side hustlemu di sini!</p>
                    </HookTop>
                    {user ? <HeroButtonWrapper>
                        <HeroButton onClick={() => {
                            const isClient = user['is_client'];
                            if (isClient) {
                                window.location = "/dashboard/client/hustles"
                            } else {
                                window.location = "/dashboard/hustlers"
                            }
                        }} bg={"#fff"} color={"#088952"} bghover={"#e6e6e6"} bgborder={"#e6e6e6"}
                            fontcolor={"#088952"} fonthovercolor={"#044127"}>
                            <span>Dashboard</span>
                        </HeroButton>
                    </HeroButtonWrapper> : <HeroButtonWrapper>
                        <HeroButton onClick={() => setOpenModal(true)} bg={"#fff"} color={"#088952"} bghover={"#e6e6e6"} bgborder={"#e6e6e6"}
                            fontcolor={"#088952"} fonthovercolor={"#044127"}>
                            <span>Jadi Hustlers</span>
                        </HeroButton>
                        <HeroButton onClick={() => window.location = "/register"} bg={"transparent"} color={"#fff"} bghover={"#088952"}>
                            <span>Kirim Side Hustle</span>
                        </HeroButton>
                    </HeroButtonWrapper>}

                </HookDetail>
            </HookContainer>
            <ModalComponent
                show={openModal} onHide={() => {
                    setOpenModal(!openModal)
                }} size="m" body={<AuthBody />}
            />
        </HookWrapper>);
}

export default Hook;