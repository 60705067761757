import Joi from "joi-browser";
import React from 'react';
import { toast } from "react-toastify";
import Form from "../../components/form";
import { login } from "../../service/authService";
import { AuthCentered, AuthForgotWrapper } from "./style";

class AuthForm extends Form {
    state = {
        data: {},
        errors: {}
    }

    schema = {
        email: Joi.string().required(),
        password: Joi.string().required()
    }

    doSubmit = async () => {
        const { data } = this.state;
        try {
            const { status, data: resp } = await login(data)
            if (status === 200) {
                window.location = "/";
                localStorage.setItem("token", resp.data["token"]);
            }
        } catch (e) {
            if (e.response && e.response.status >= 400) {
                toast.error(e.response.data.message);
            }
        }
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {this.renderInput("email", "Email", "Masukkan email")}
                {this.renderInput("password", "Kata Sandi", "Masukkan Kata Sandi", "password")}
                <AuthForgotWrapper>
                    <p>Lupa Kata Sandi? <span>Atur Ulang.</span></p>
                </AuthForgotWrapper>
                {this.renderButton('Masuk', '100%')}
                <AuthCentered>
                    <p>Belum punya akun? <span onClick={() => window.location = "/register"}>Daftar.</span></p>
                </AuthCentered>
            </form>
        );
    }
}

export default AuthForm;