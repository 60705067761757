import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import { ButtonDashboard } from '../../../page/dashboard/style';
import { StyledTable } from "../../../page/dashboardHustlers/style";
import { FileNameSpan } from '../../../page/job_detail/style';
import { getHustlesApplicants, getJobDetail, updateApplicant } from "../../../service/jobService";
import { TableTd } from "../../table/tableBody/tableBodyElements";
import { ModalWrapper } from "../auth/style";
import { WithdrawHeader } from '../withdraw/style';
import { ApplicantFileWrapper } from './style';

const Applicants = ({ projectID, uuid }) => {
    const [data, setData] = useState([]);
    const [hustle, setHustle] = useState({});
    const [selectedApplicant, setSelectedApplicant] = useState({});
    const [showFile, setShowFile] = useState(false);
    const fetchApplicants = async () => {
        const { status, data } = await getHustlesApplicants(projectID);
        if (status === 200) {
            setData(data.data)
        }
    }

    const fetchHustle = async () => {
        const { status, data } = await getJobDetail(uuid);
        if (status === 200) {
            setHustle(data.data);
        }
    }

    useEffect(() => {
        fetchApplicants()
        fetchHustle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ModalWrapper>
            <WithdrawHeader>
                <h1>Judul</h1>
                <p>{hustle['title']}</p>
            </WithdrawHeader>
            <WithdrawHeader>
                <h1>Deskripsi</h1>
                <p>{hustle['description'] && parse(hustle['description'])}</p>
            </WithdrawHeader>
            <WithdrawHeader style={{ marginBottom: "1rem" }}>
                <h1>Pelamar</h1>
            </WithdrawHeader>
            <StyledTable>
                <colgroup>
                    <col style={{ width: "100px" }} />
                    <col style={{ width: "180px" }} />
                    <col style={{ width: "100px" }} />
                    <col style={{ width: "120px" }} />
                    <col style={{ width: "80px" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>Nama</th>
                        <th>Portfolio</th>
                        <th>Bid</th>
                        <th>Informasi</th>
                        <th>Aksi</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((apply, index) => (<tr key={index}>
                        <TableTd>
                            <h1>{apply['applier_name']}</h1>
                            <p>{new Date(apply['applied_at']).toLocaleDateString()}</p>
                        </TableTd>
                        <TableTd>
                            <p>{apply['portfolio']}</p>
                        </TableTd>
                        <td><CurrencyFormat value={apply['bid']} displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Rp. '} /></td>
                        <TableTd>
                            <p>{apply['applier_email']}</p>
                            <p>{apply['applier_phone_number']}</p>
                        </TableTd>
                        <td><ButtonDashboard style={{ margin: "0" }} onClick={async () => {
                            const response = window.confirm(`Yakin rekrut ${apply['applier_name']}?`)
                            if (response) {
                                try {
                                    const { status } = await updateApplicant({
                                        project_apply_id: apply['id'],
                                        status: 1,
                                        project_id: apply['project_id'],
                                        applier_id: apply['applier_id']
                                    })
                                    if (status === 200) {
                                        toast.success("Berhasil update applicant");
                                        setTimeout(function () {
                                            window.location = "/dashboard/client/hustles";
                                        }, 500)
                                    }
                                } catch (e) {

                                }
                            }
                        }}>
                            <span>Rekrut</span>
                        </ButtonDashboard>
                            <ButtonDashboard style={{ marginTop: "4px" }} onClick={() => {
                                setSelectedApplicant(apply);
                                setShowFile(true);
                            }}>
                                <span>Lihat File</span>
                            </ButtonDashboard></td>
                    </tr>))}
                </tbody>
            </StyledTable>
            {showFile && selectedApplicant['file_urls'] && selectedApplicant['file_urls'].length > 0 ? <ApplicantFileWrapper>
                <h1>File Pendukung {selectedApplicant['applier_name']}</h1>
                {selectedApplicant['file_urls'].map((url, index) => <FileNameSpan key={index} onClick={() => window.open(url)}>{decodeURI(url.split("/")[6])}</FileNameSpan>)}
            </ApplicantFileWrapper> : null}
        </ModalWrapper>
    )
}

export default Applicants;
