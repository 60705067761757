import styled from "styled-components";

export const FormLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 0.5rem;
  color: #111111;
`;

export const FormComponentWrapper = styled.div`
  margin: 21px 0 0;
  width: 100%;

  & > span {
    color: #ef5350;
    font-size: 12px;
  }
`;

export const FormLabelFile = styled.label`
  font-size: 0.9rem;
  color: #333;
  font-weight: 800;
  margin: 0.5rem 0 0.25rem;
  border-radius: 10px;
`;

export const FormInput = styled.input`
  border: 1px solid #d1d1d1;
  height: 40px;
  display: block;
  font-size: 13px;
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : "1rem")};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  background: #fff;
  color: #0b0c0c;
  border-radius: 4px;

  &:hover {
    outline: 0;
    box-shadow: none;
  }

  &:active,
  &:focus {
    border-color: #088952;
  }
`;

export const FormButton = styled.button`
  border-radius: 30px;
  border: 0;
  color: #fff;
  background: #088952;
  font-size: 13px;
  padding: 8px 1.5rem;
  align-self: center;
  margin-top: ${({ margin }) => (margin ? margin : "1rem")};
  cursor: pointer;
  width: ${({ width }) => width};

  &:disabled {
    background: #c4c4c4;
  }

  &:focus {
    outline: none;
  }
`;

export const FormSelect = styled.select`
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  height: 40px;
  font-size: 13.5px;
  padding-left: 1rem;
  outline: none;
  width: 100%;
  background: #fff;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & > label {
    color: #333;
    font-size: 14px;
    margin-left: 4px;
    font-weight: 800;
  }

  & > svg {
    display: ${({ display }) => display};
    position: absolute;
    right: 20px;
  }

  & > .offer {
    border: 1px solid #d1d1d1;
    height: 40px;
    display: block;
    font-size: 14px;
    padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : "1rem")};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    width: 100%;
    background: #fff;
    color: #0b0c0c;
    border-radius: 4px;
    font-size: 13px;

    &:hover {
      outline: 0;
      box-shadow: none;
    }

    &:active,
    &:focus {
      border-color: #088952;
    }
  }

  @media screen and (min-width: 1024px) {
    & > svg {
      right: 15px;
    }
  }
`;

export const FormInputFile = styled.input`
  font-size: 12px;
  width: 100%;
  padding: 4px 0;
  margin-bottom: 1rem;

  &:focus {
    outline: 0;
  }
`;
