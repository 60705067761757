import React, { useEffect, useState } from 'react';
import CurrencyFormat from "react-currency-format";
import { useSearchParams } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import { CategoriesJob } from "../../form/newJob/data";
import Navbar from "../../section/navbar";
import { getHustles } from "../../service/jobService";
import { dayLefts } from "../../utils";
import { JobDetailContainer } from "../job_detail/style";
import {
    FilterSidebar,
    FilterWrapper,
    HustleFooter,
    HustleFooterDetail,
    HustleFooterRight,
    HustleInfoClient,
    HustleItemHeader,
    HustleItemHeaderLeft,
    HustlesContainer,
    HustlesGrid,
    HustlesItem,
    HustlesPrimary,
    HustlesWrapper
} from "./style";

const Jobs = (props) => {
    const [hustles, setHustles] = useState([]);
    const [filteredHustles, setFilteredHustles] = useState([]);
    const [searchParams] = useSearchParams();
    const [filter, setFilter] = useState({});

    const fetchHustles = async () => {
        try {
            const { status, data } = await getHustles(filter);
            if (status === 200) {
                setHustles(data.data);
                setFilteredHustles(data.data);
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        let category = searchParams.get("category");
        let currFilter = filter;
        if (category) {
            currFilter['category'] = `${searchParams.get("category")}&`;
        }
        setFilter(currFilter);
        fetchHustles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFilterChange = (value) => {
        if (value === "Semua") {
            setFilteredHustles(hustles);
            window.location = "/hustles"
            return
        }
        window.location = `/hustles?category=${value}`
    }

    return (
        <>
            <Navbar />
            <HustlesWrapper>
                <JobDetailContainer>
                    <HustlesContainer>
                        <FilterSidebar>
                            <h1>Filter</h1>
                            <FilterWrapper>
                                <h2>Kategori</h2>
                                {CategoriesJob.map((category, index) => <p
                                    onClick={() => onFilterChange(category['name'])}
                                    key={index}>{category["name"]}</p>)}
                            </FilterWrapper>
                        </FilterSidebar>
                        <HustlesPrimary>
                            <HustlesGrid>
                                {filteredHustles && filteredHustles.map((hustle, index) => <HustlesItem key={index}
                                    onClick={() => window.location = `/hustle/${hustle['uuid']}`}>
                                    <HustleItemHeader>
                                        <img src={logo} alt="" />
                                        <HustleItemHeaderLeft>
                                            <h3>{hustle['job_title']}</h3>
                                            <HustleInfoClient>
                                                oleh <span>{hustle['created_by']}</span> pada <p>{hustle['category']}</p>
                                            </HustleInfoClient>
                                        </HustleItemHeaderLeft>
                                    </HustleItemHeader>
                                    <HustleFooter>
                                        <HustleFooterDetail>
                                            <span style={{
                                                color: "#6c4cbe",
                                                background: "#f5ecff",
                                                padding: "5px 12px",
                                                borderRadius: "50px",
                                            }}>{hustle['project_bid'].includes("Penawaran") ? hustle['project_bid'] :
                                                <CurrencyFormat value={hustle['project_bid']} displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'Rp. '} />}</span>
                                            <span style={{
                                                color: "#007456",
                                                background: "rgba(0,116,86,.05)",
                                                padding: "5px 12px",
                                                borderRadius: "50px",
                                            }}>{hustle.total_bidder} Pelamar</span>
                                            <span style={{
                                                color: "#007456",
                                                background: "rgba(0,116,86,.05)",
                                                padding: "5px 12px",
                                                borderRadius: "50px",
                                            }}>{hustle.job_status}
                                            </span>
                                        </HustleFooterDetail>
                                        <HustleFooterRight>
                                            <p><span>{dayLefts(hustle.deadline)}</span> hari lagi untuk apply</p>
                                        </HustleFooterRight>
                                    </HustleFooter>
                                </HustlesItem>)}
                            </HustlesGrid>
                        </HustlesPrimary>
                    </HustlesContainer>
                </JobDetailContainer>
            </HustlesWrapper>
        </>
    )
}

export default Jobs;