import styled from "styled-components";

export const HookWrapper = styled.section`
  background: #088952;
  padding: 2rem;

  @media screen and (min-width: 1024px) {
    padding: 4rem 0;
  }
`;

export const HookContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

export const HookDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HookTop = styled.div`
  text-align: center;

  & > h1 {
    color: #fff;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -.5px;
    font-weight: 700;
  }

  & > p {
    font-size: 17.5px;
    color: #f5f5f5;
    margin: 1rem 0;
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;