import React from "react";
import applicant from '../../../assets/icons/dashboard/sidenavbar/applicant.svg';
import home from "../../../assets/icons/dashboard/sidenavbar/home.svg";
import job from '../../../assets/icons/dashboard/sidenavbar/job.svg';
import packageIcon from '../../../assets/icons/dashboard/sidenavbar/package.svg';
import profile from "../../../assets/icons/dashboard/sidenavbar/profile.svg";
import logout from '../../../assets/icons/logout.svg';
import message from '../../../assets/icons/message.svg';
import logo from '../../../assets/images/logotext.png';
import { logOut } from "../../../service/authService";
import { SideNavbar, SideNavbarContainer, SideNavbarHeader, SideNavbarMenu } from "../style";

export const SideNavigationBarClient = () => {
    return (
        <SideNavbar>
            <SideNavbarContainer>
                <SideNavbarHeader>
                    <img src={logo} alt="" onClick={() => window.location = "/"} />
                </SideNavbarHeader>
                <SideNavbarMenu>

                    <li><a href="/">
                        <span><img src={home} alt="" /></span>
                        <span>Home</span>
                    </a></li>
                    <li><a href="/dashboard/client/hustles">
                        <span><img src={job} alt="" /></span>
                        <span>Hustles</span>
                    </a></li>
                    <li><a href="/dashboard/client/applicants" >
                        <span><img src={applicant} alt="" /></span>
                        <span>Applicants</span>
                    </a></li>
                    {/* <li><a href="#">
                                <span><img src={candidate} alt=""/></span>
                                <span>Candidate</span>
                            </a></li> */}
                    <li><a href="/dashboard/client/orders">
                        <span><img src={packageIcon} alt="" /></span>
                        <span>Order</span>
                    </a></li>
                    <li><a href="/dashboard/client/message">
                        <span><img src={message} alt="" /></span>
                        <span>Message</span>
                    </a></li>
                    <li><a href="/dashboard/client/profile">
                        <span><img src={profile} alt="" /></span>
                        <span>Profile</span>
                    </a></li>
                    <li><button href="#" onClick={async () => {
                        const { status } = await logOut();
                        if (status === 200) {
                            localStorage.removeItem("token");
                            setTimeout(function () {
                                window.location = "/"
                            }, 500)
                        }
                    }}>
                        <span><img src={logout} alt="" /></span>
                        <span>Logout</span>
                    </button></li>
                </SideNavbarMenu>
            </SideNavbarContainer>
        </SideNavbar>
    )
}