import React from "react";
import { SideNavbarHustler } from "../../../components/sidenavbar";
import { CommentBody } from "../../dashboard/comments/commentsBody";
import { MenuHeaderWrapper } from "../../dashboard/hustles/style";
import { DashboardContentWrapper } from "../../dashboard/style";
import { DashboardClientWrapper } from "../../dashboard/wrapper";

export const CommentPage = () => {
    return (
        <DashboardContentWrapper>
            <SideNavbarHustler />
            <DashboardClientWrapper
                child={<>
                    <MenuHeaderWrapper>
                        <h1>Percakapan</h1>
                    </MenuHeaderWrapper>
                    <CommentBody />
                </>}
            />
        </DashboardContentWrapper>
    )
}