import React, { useEffect, useState } from "react";
import * as Lu from 'react-icons/lu';
import Select from "react-select";
import { toast } from "react-toastify";
import RichText from "../../../../components/form/richtexteditor";
import { FormLabel } from "../../../../components/form/style";
import { FormLabelWrapper } from "../../../../form/newJob/style";
import { uploadImage } from "../../../../service/gcloudservice";
import { getUser, updateUser } from "../../../../service/userService";
import { MenuHeaderWrapper } from "../../../dashboard/hustles/style";
import { DashboardContentWrapper } from "../../../dashboard/style";
import { Card, CardBody } from "../../style";
import { Category } from "./data";
import { FileImageWrapper, FileUploader, ProfileDataContainer, ProfileDataFlex, ProfileInputWrapper, ProfileSectionWrapper, SubmitButton, WarningOrangeWrapper, WarningWrapper } from "./style";

const Gender = [
    {
        value: "Pria",
        label: "Pria"
    },
    {
        value: "Wanita",
        label: "Wanita"
    }
];

export const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#ddd',
        minHeight: '47px',
        height: '47px',
        boxShadow: state.isFocused ? null : null,
        fontSize: '13px'
    }),
    option: (provided) => ({
        ...provided,
        fontSize: "13px"
    })
}

export const ProfileBody = () => {
    const [user, setUser] = useState({});
    const [data, setData] = useState({});
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedGender, setSelectedGender] = useState({});
    const [files, setFiles] = useState({
        "ktp": [],
        "npwp": [],
        "resume": []
    });
    const [objectURLs, setObjectURLs] = useState({
        "ktp": [],
        "npwp": [],
        "resume": []
    });

    const fetchUser = async () => {
        try {
            const { status, data } = await getUser();
            if (status === 200) {
                setUser(data.data);
                setData(data.data);
                const category = data.data.category;
                const gender = data.data.gender;
                setSelectedCategory(Category.filter(ctg => ctg.value === category))
                setSelectedGender(Gender.filter(gdr => gdr.value === gender))
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        fetchUser();
    }, [])

    const onValueChange = ({ currentTarget: input }) => {
        data[input.name] = input.type === "number" ? parseInt(input.value, 10) : input.value;
        setData(data);
    }

    const setDescription = (value) => {
        data["description"] = value;
        setData(data);
    }

    const setSkills = (value) => {
        data["skills"] = value;
        setData(data);
    }

    const setCategory = (value) => {
        if (value) {
            setSelectedCategory(value);
            data["category"] = value['value'];
        } else {
            data["category"] = "";
            setSelectedCategory({});
        }
        setData(data);
    }

    const setGender = (value) => {
        if (value) {
            setSelectedGender(value);
            data["gender"] = value['value'];
        } else {
            data["gender"] = ""
            setSelectedGender({});
        }
        setData(data);
    }

    const onUploadFile = ({ currentTarget: input }) => {
        let currFiles = [...files[input.name]];
        let currObjectURLs = [...objectURLs[input.name]];
        const currData = { ...data };
        for (let i = 0; i < input.files.length; i++) {
            currData[input.name] = URL.createObjectURL(input.files[i]);
            currFiles.push(input.files[i]);
            currObjectURLs.push(URL.createObjectURL(input.files[i]));
        }

        files[input.name] = currFiles;
        objectURLs[input.name] = currObjectURLs;
        setFiles(files);
        setObjectURLs(objectURLs);
        setData(currData);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const ktpURLs = [];
        const npwpURLs = [];
        const resumeURLs = [];
        let body = data;

        if (files["ktp"].length > 0) {
            for (const file of files["ktp"]) {
                let fileName = `KTP_${user['full_name']}_${user['email']}_${new Date().getMilliseconds()}`
                const { status, data } = await uploadImage(file, "ktp", fileName);
                if (status === 200) {
                    const fileURL = data.data["pathname"];
                    ktpURLs.push(fileURL);
                }
            }

            body["identity_image_url"] = ktpURLs[0];
        }

        if (files["npwp"].length > 0) {
            for (const file of files["npwp"]) {
                const { status, data } = await uploadImage(file, "npwp");
                if (status === 200) {
                    const fileURL = data.data["pathname"];
                    npwpURLs.push(fileURL);
                }
            }

            body["npwp_image_url"] = npwpURLs[0];
        }

        if (files["resume"].length > 0) {
            for (const file of files["resume"]) {
                const { status, data } = await uploadImage(file, "resume");
                if (status === 200) {
                    const fileURL = data.data["pathname"];
                    resumeURLs.push(fileURL);
                }
            }

            body["resume_url"] = resumeURLs[0];
        }

        try {
            const { status } = await updateUser(body);
            if (status === 200) {
                toast.success('Berhasil update');
                setTimeout(function () {
                    window.location = window.location.href;
                }, 500)
            }
        } catch (e) {

        }
    }

    return (
        <DashboardContentWrapper>
            <MenuHeaderWrapper>
                <h1>Pengaturan Profil</h1>
            </MenuHeaderWrapper>
            <form onSubmit={onSubmit} style={{ marginBottom: "1rem" }}>
                <Card>
                    <CardBody>
                        <ProfileSectionWrapper>
                            <h1>Informasi Dasar</h1>
                            <ProfileDataFlex>
                                <ProfileDataContainer>
                                    <FormLabel>Nama Lengkap</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['full_name']} name="full_name" onChange={onValueChange} />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                    <FormLabel>Email</FormLabel>
                                    <ProfileInputWrapper disabled={true} type="text" defaultValue={data && data['email']} name="email" onChange={onValueChange} />
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                            <ProfileDataFlex style={{ marginBottom: "8px" }}>
                                <ProfileDataContainer>
                                    <FormLabel>Username</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['username']} name="username" onChange={onValueChange} />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                    <FormLabel>No Handphone</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['phone_number']} name="phone_number" onChange={onValueChange} />
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                            <ProfileDataFlex style={{ marginBottom: "8px" }}>
                                <ProfileDataContainer>
                                    <FormLabel>Posisi</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['position']} name="position" onChange={onValueChange} />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                    <FormLabel>Kategori</FormLabel>
                                    <Select
                                        options={Category}
                                        isClearable={true}
                                        isSearchable={true}
                                        styles={selectStyles}
                                        onChange={setCategory}
                                        value={selectedCategory}
                                    />
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                        </ProfileSectionWrapper>
                        <ProfileSectionWrapper style={{ marginTop: "2rem" }}>
                            <FormLabelWrapper>
                                <FormLabel style={{ marginBottom: "4px" }}>Deskripsi</FormLabel>
                                <span>Jelaskan secara singkat mengenai dirimu</span>
                            </FormLabelWrapper>
                            <RichText setContent={setDescription} content={user['description']} />
                        </ProfileSectionWrapper>
                        <ProfileSectionWrapper>
                            <ProfileDataFlex>
                                <ProfileDataContainer>
                                    <FormLabel>Tanggal Lahir</FormLabel>
                                    <ProfileInputWrapper type="date" defaultValue={data && data['dob']} name="dob" onChange={onValueChange} />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                    <FormLabel>Pengalaman (Tahun)</FormLabel>
                                    <ProfileInputWrapper type="number" defaultValue={data && data['yoe']} name="yoe" onChange={onValueChange} />
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                            <ProfileDataFlex>
                                <ProfileDataContainer>
                                    <FormLabel>Gender</FormLabel>
                                    <Select
                                        options={Gender}
                                        isClearable={true}
                                        isSearchable={true}
                                        styles={selectStyles}
                                        onChange={setGender}
                                        value={selectedGender}
                                    />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                    <FormLabel>Kota</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['city']} name="city" onChange={onValueChange} />
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                        </ProfileSectionWrapper>
                        <ProfileSectionWrapper style={{ marginTop: "2rem" }}>
                            <FormLabelWrapper>
                                <FormLabel style={{ marginBottom: "4px" }}>Kemampuan</FormLabel>
                                <span>Jelaskan secara singkat mengenai kemampuanmu</span>
                            </FormLabelWrapper>
                            <RichText setContent={setSkills} content={data['skills']} />
                        </ProfileSectionWrapper>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <ProfileSectionWrapper>
                            <h1>Identitas Diri</h1>
                            <ProfileDataFlex>
                                <ProfileDataContainer>
                                    <FormLabel>Nomor Identitas</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['linkedin_url']} name="linkedin_url" onChange={onValueChange} placeholder="Nomor Kartu Tanda Penduduk" />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                    <FormLabel>Nomor NPWP</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['instagram_url']} name="instagram_url" onChange={onValueChange} placeholder="Nomor Wajib Pajak" />
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                            <ProfileDataFlex>
                                <ProfileDataContainer>
                                    <FormLabel>Nama Bank</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['payment_method']} name="payment_method" onChange={onValueChange} placeholder="" />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                    <FormLabel>Nama Penerima</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['payment_name']} name="payment_name" onChange={onValueChange} placeholder="Penerima pada Bank" />
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                            <ProfileDataFlex style={{ marginBottom: "8px" }}>
                                <ProfileDataContainer>
                                    <FormLabel>Nomor Rekening</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['payment_number']} name="payment_number" onChange={onValueChange} placeholder="" />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                        </ProfileSectionWrapper>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <ProfileSectionWrapper>
                            <h1>Sosial Media</h1>
                            <ProfileDataFlex>
                                <ProfileDataContainer>
                                    <FileUploader htmlFor="resume" >
                                        <Lu.LuArrowUpFromLine /> {files['resume'].length > 0 ? files['resume'][0].name : "Upload CV"}
                                    </FileUploader>
                                    <ProfileInputWrapper style={{ display: "none" }} id="resume" type="file" defaultValue={data && data['resume']} name="resume" onChange={onUploadFile} accept=".doc, .docx,.pdf" />
                                    <span style={{ display: "block", marginTop: ".8rem" }}>Upload file: doc, docx, pdf</span>
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                            <ProfileDataFlex>
                                <ProfileDataContainer>
                                    <FormLabel>LinkedIn</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['linkedin_url']} name="linkedin_url" onChange={onValueChange} placeholder="https://linkedin.com/in/username" />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                    <FormLabel>Instagram</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['instagram_url']} name="instagram_url" onChange={onValueChange} placeholder="https://instagram.com/username" />
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                            <ProfileDataFlex style={{ marginBottom: "8px" }}>
                                <ProfileDataContainer>
                                    <FormLabel>Portfolio</FormLabel>
                                    <ProfileInputWrapper type="text" defaultValue={data && data['instagram_url']} name="instagram_url" onChange={onValueChange} placeholder="Link Web/Behance/GDrive" />
                                </ProfileDataContainer>
                                <ProfileDataContainer>
                                </ProfileDataContainer>
                            </ProfileDataFlex>
                        </ProfileSectionWrapper>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <ProfileSectionWrapper>
                            <h1>Identitas Penduduk</h1>
                            <ProfileDataContainer>
                                <p>Untuk melakukan penarikan saldo, Anda diwajibkan untuk mengunggah foto asli Kartu Tanda Penduduk</p>
                                {data['is_identity_verified'] === 1 || data['identity_image_url'] ? null : <WarningWrapper>
                                    Mohon unggah foto identitas diri Anda untuk dapat menikmati seluruh fitur Hustlers
                                </WarningWrapper>}
                                {data['identity_image_url'] && data['is_identity_verified'] !== 1 ?
                                    <WarningOrangeWrapper>
                                        Data Anda sedang diverifikasi oleh tim kami. Mohon menunggu ya
                                    </WarningOrangeWrapper>
                                    : null}
                                {data['identity_image_url'] && <FileImageWrapper>
                                    <img src={data['identity_image_url']} alt={data['identity_image_url']} />
                                </FileImageWrapper>}
                                {objectURLs["ktp"].length > 0 && <FileImageWrapper>
                                    {objectURLs["ktp"].map((url, index) => <img key={index} src={url} alt={url} />)}
                                </FileImageWrapper>}
                                <FileUploader htmlFor="ktp">
                                    Upload KTP
                                </FileUploader>
                                <ProfileInputWrapper style={{ display: "none" }} id="ktp" type="file" defaultValue={data && data['ktp']} name="ktp" onChange={onUploadFile} accept="image/*" />
                            </ProfileDataContainer>
                        </ProfileSectionWrapper>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <ProfileSectionWrapper>
                            <h1>Identitas NPWP</h1>
                            <ProfileDataContainer>
                                <p>Pastikan nomor NPWP dengan nomor yang ada di foto sama</p>
                                {data['npwp_image_url'] && <FileImageWrapper>
                                    <img src={data['identity_image_url']} alt={data['identity_image_url']} />
                                </FileImageWrapper>}
                                {objectURLs["npwp"].length > 0 && <FileImageWrapper>
                                    {objectURLs["npwp"].map((url, index) => <img key={index} src={url} alt={url} />)}
                                </FileImageWrapper>}
                                <FileUploader htmlFor="npwp">
                                    Upload NPWP
                                </FileUploader>
                                <ProfileInputWrapper style={{ display: "none" }} id="npwp" type="file" defaultValue={data && data['npwp']} name="npwp" onChange={onUploadFile} accept="image/*" />
                            </ProfileDataContainer>
                        </ProfileSectionWrapper>
                    </CardBody>
                </Card>
                <SubmitButton>
                    Simpan
                </SubmitButton>
            </form>
        </DashboardContentWrapper>
    )
}