import React from 'react';
import { SideNavbarHustler } from '../../components/sidenavbar';
import { getCurrentUser } from "../../service/authService";
import {
    DashboardContent,
    DashboardFlex,
    DashboardFlexMenu,
    DashboardNavbar,
    DashboardWrapper
} from "../dashboard/style";
import HustlesDH from "./hustles";

const user = getCurrentUser();

const DashboardHustler = (props) => {
    return (
        <DashboardWrapper>
            <SideNavbarHustler />
            <DashboardContent>
                <DashboardNavbar>
                    <DashboardFlex>
                        <DashboardFlexMenu>
                            <h1>Hello, {user['username']}</h1>
                        </DashboardFlexMenu>
                    </DashboardFlex>
                </DashboardNavbar>
                <HustlesDH />
            </DashboardContent>
        </DashboardWrapper>
    )
}

export default DashboardHustler;