import React, { useState } from 'react';
import * as Fa from 'react-icons/fa6';
import hero from '../../assets/images/hero-01.svg';
import { ModalComponent } from "../../components/modal";
import AuthBody from "../../components/modal/auth";
import { getCurrentUser } from '../../service/authService';
import { CommonContainer, HeroButton, HeroButtonWrapper, HeroDetail, HeroWrapper } from "./style";

const user = getCurrentUser();

const Hero = (props) => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <HeroWrapper>
            <CommonContainer>
                <HeroDetail>
                    {/*<h1>Hire verified & Trusted freelancers with reviews</h1>*/}
                    <h1>Platform Pencari Side Hustle terpercaya dan lengkap</h1>
                    <p><Fa.FaRegCircleCheck color={"#088952"} /> 100+ Projek Side Hustle dengan Variasi berbeda.
                    </p>
                    {user ? <HeroButtonWrapper>
                        <HeroButton onClick={() => {
                            const isClient = user['is_client'];
                            if (isClient) {
                                window.location = "/dashboard/client/hustles"
                            } else {
                                window.location = "/dashboard/hustler/hustles"
                            }
                        }} bg={"#088952"} color={"#088952"}
                            bghover={"#06653c"} bgborder={"#055935"}>
                            <span>Dashboard</span>
                        </HeroButton>
                    </HeroButtonWrapper> : <HeroButtonWrapper>
                        <HeroButton onClick={() => setOpenModal(true)} bg={"#088952"} color={"#088952"}
                            bghover={"#06653c"} bgborder={"#055935"}>
                            <span>Jadi Hustlers</span>
                        </HeroButton>
                        <HeroButton onClick={() => window.location = "/register"} bg={"transparent"} color={"#088952"}
                            bghover={"#088952"}>
                            <span>Kirim Side Hustle</span>
                        </HeroButton>
                    </HeroButtonWrapper>}

                    <p><Fa.FaRegistered color={"#088952"} /> Dengan platform fee terendah.</p>
                </HeroDetail>
                <img src={hero} alt="" />
                {/*<ImageWrapper>*/}
                {/*    <img src={hero} alt=""/>*/}
                {/*</ImageWrapper>*/}
            </CommonContainer>
            <ModalComponent
                show={openModal} onHide={() => {
                    setOpenModal(!openModal)
                }} size="m" body={<AuthBody />}
            />
        </HeroWrapper>);
}

export default Hero;