import React from "react";
import { MenuHeaderWrapper } from "../hustles/style";
import { SideNavigationBarClient } from "../sidenavbar";
import { DashboardContentWrapper } from "../style";
import { DashboardClientWrapper } from "../wrapper";
import { CommentBody } from "./commentsBody";

export const Comments = () => {
    return (
        <DashboardContentWrapper>
            <SideNavigationBarClient />
            <DashboardClientWrapper
                child={<>
                    <MenuHeaderWrapper>
                        <h1>Percakapan</h1>
                    </MenuHeaderWrapper>
                    <CommentBody />
                </>}
            />
        </DashboardContentWrapper>
    )
}