import React, { Component } from 'react';
import bg from '../../assets/images/bgregister.jpeg';
import { DataContext } from "../../components/context";
import { ModalComponent } from "../../components/modal";
import RegisterDone from "../../components/modal/registerDone";
import RegisterForm from "../../form/register";
import Navbar from "../../section/navbar";
import { AuthContainer, AuthFormContainer, AuthFormFlex, AuthFormWrapper, AuthImage, AuthWrapper } from "./style";

class RegisterHustler extends Component {
    static contextType = DataContext;

    render() {
        const { email, isRegistering } = this.context;
        return (
            <AuthWrapper>
                <Navbar />
                <AuthContainer>
                    <AuthImage>
                        <img src={bg} alt="" />
                    </AuthImage>
                    <AuthFormWrapper>
                        <AuthFormFlex>
                            <AuthFormContainer>
                                <h1>Daftar</h1>
                                <RegisterForm />
                            </AuthFormContainer>
                        </AuthFormFlex>
                    </AuthFormWrapper>
                </AuthContainer>
                <ModalComponent
                    show={isRegistering}
                    onHide={() => window.location = "/"}
                    body={<RegisterDone email={email} />}
                />
            </AuthWrapper>
        );
    }
}

export default RegisterHustler;