import React from 'react';
import * as Fa from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { CardFooterCenter } from "../how/style";
import { Category, CategoryDetail, CategoryGrid, CategoryWrapper, HeroContainer, MoreCategory } from "./style";

const Categories = (props) => {
    const navigate = useNavigate();

    return (
        <CategoryWrapper>
            <HeroContainer>
                <h1>Kategori Popular</h1>
                <p>Temukan banyak jenis <em>Side Hustle</em> yang sesuai dengan kemampuanmu</p>
            </HeroContainer>
            <CategoryGrid>
                <Category onClick={() => navigate("/hustles?category=Web dan Pemrograman")}>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
                        <path
                            d="M24 9L18.343 14.657L16.929 13.243L21.172 9L16.929 4.757L18.343 3.343L24 9ZM2.828 9L7.071 13.243L5.657 14.657L0 9L5.657 3.343L7.07 4.757L2.828 9ZM9.788 18H7.66L14.212 0H16.34L9.788 18Z"
                            fill="white"></path>
                    </svg></span>
                    <CategoryDetail>
                        <h1>Web & Pemrograman</h1>
                    </CategoryDetail>
                </Category>
                <Category onClick={() => navigate("/hustles?category=Desain Grafis, Interior & Branding")}>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path
                        d="M10 0C15.522 0 20 3.978 20 8.889C19.9992 10.3622 19.4136 11.7748 18.3717 12.8165C17.3299 13.8581 15.9172 14.4435 14.444 14.444H12.478C11.556 14.444 10.811 15.189 10.811 16.111C10.811 16.533 10.978 16.922 11.233 17.211C11.5 17.511 11.667 17.9 11.667 18.333C11.667 19.256 10.9 20 10 20C4.478 20 0 15.522 0 10C0 4.478 4.478 0 10 0ZM8.811 16.111C8.81061 15.6293 8.90519 15.1523 9.08933 14.7072C9.27347 14.2622 9.54357 13.8578 9.88416 13.5172C10.2248 13.1766 10.6292 12.9065 11.0742 12.7223C11.5193 12.5382 11.9963 12.4436 12.478 12.444H14.444C15.3866 12.4435 16.2905 12.0689 16.9572 11.4026C17.6239 10.7363 17.9989 9.8326 18 8.89C18 5.139 14.468 2 10 2C7.93558 1.99812 5.95034 2.79436 4.45938 4.22225C2.96841 5.65014 2.08715 7.59913 1.99986 9.66171C1.91256 11.7243 2.62599 13.7408 3.99097 15.2895C5.35595 16.8383 7.2668 17.7994 9.324 17.972C8.98917 17.4093 8.81197 16.7668 8.811 16.112V16.111ZM5.5 10C5.10218 10 4.72064 9.84196 4.43934 9.56066C4.15804 9.27936 4 8.89782 4 8.5C4 8.10218 4.15804 7.72064 4.43934 7.43934C4.72064 7.15804 5.10218 7 5.5 7C5.89782 7 6.27936 7.15804 6.56066 7.43934C6.84196 7.72064 7 8.10218 7 8.5C7 8.89782 6.84196 9.27936 6.56066 9.56066C6.27936 9.84196 5.89782 10 5.5 10ZM14.5 10C14.1022 10 13.7206 9.84196 13.4393 9.56066C13.158 9.27936 13 8.89782 13 8.5C13 8.10218 13.158 7.72064 13.4393 7.43934C13.7206 7.15804 14.1022 7 14.5 7C14.8978 7 15.2794 7.15804 15.5607 7.43934C15.842 7.72064 16 8.10218 16 8.5C16 8.89782 15.842 9.27936 15.5607 9.56066C15.2794 9.84196 14.8978 10 14.5 10ZM10 7C9.60218 7 9.22064 6.84196 8.93934 6.56066C8.65804 6.27936 8.5 5.89782 8.5 5.5C8.5 5.10218 8.65804 4.72064 8.93934 4.43934C9.22064 4.15804 9.60218 4 10 4C10.3978 4 10.7794 4.15804 11.0607 4.43934C11.342 4.72064 11.5 5.10218 11.5 5.5C11.5 5.89782 11.342 6.27936 11.0607 6.56066C10.7794 6.84196 10.3978 7 10 7Z"
                        fill="white"></path></svg></span>
                    <CategoryDetail>
                        <h1>Desain Grafis, Interior & Branding</h1>
                    </CategoryDetail>
                </Category>
                <Category onClick={() => navigate("/hustles?category=Pemasaran dan Periklanan")}>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                        viewBox="0 0 22 22" fill="none"><path
                            d="M12 0L12.001 3.062C13.7632 3.28479 15.4013 4.08743 16.6572 5.34351C17.9131 6.5996 18.7155 8.23775 18.938 10H22V12L18.938 12.001C18.7153 13.7631 17.9128 15.401 16.6569 16.6569C15.401 17.9128 13.7631 18.7153 12.001 18.938L12 22H10V18.938C8.23775 18.7155 6.5996 17.9131 5.34351 16.6572C4.08743 15.4013 3.28479 13.7632 3.062 12.001L0 12V10H3.062C3.28459 8.23761 4.08713 6.59934 5.34324 5.34324C6.59934 4.08713 8.23761 3.28459 10 3.062V0H12ZM11 5C9.4087 5 7.88258 5.63214 6.75736 6.75736C5.63214 7.88258 5 9.4087 5 11C5 12.5913 5.63214 14.1174 6.75736 15.2426C7.88258 16.3679 9.4087 17 11 17C12.5913 17 14.1174 16.3679 15.2426 15.2426C16.3679 14.1174 17 12.5913 17 11C17 9.4087 16.3679 7.88258 15.2426 6.75736C14.1174 5.63214 12.5913 5 11 5ZM11 9C11.5304 9 12.0391 9.21071 12.4142 9.58579C12.7893 9.96086 13 10.4696 13 11C13 11.5304 12.7893 12.0391 12.4142 12.4142C12.0391 12.7893 11.5304 13 11 13C10.4696 13 9.96086 12.7893 9.58579 12.4142C9.21071 12.0391 9 11.5304 9 11C9 10.4696 9.21071 9.96086 9.58579 9.58579C9.96086 9.21071 10.4696 9 11 9Z"
                            fill="white"></path></svg></span>
                    <CategoryDetail>
                        <h1>Pemasaran & Periklanan</h1>
                    </CategoryDetail>
                </Category>
                <Category onClick={() => {
                    let URL = encodeURIComponent("Penulisan dan Penerjemahan");
                    navigate(`hustles?category=${URL}`);
                }}>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g
                        clipPath="url(#clip0_2181_24351)"><path
                            d="M6.94 14.036C6.707 14.66 6.51 15.236 6.334 15.819C7.294 15.122 8.435 14.68 9.752 14.515C12.265 14.201 14.498 12.542 15.628 10.457L14.172 9.002L15.585 7.587L16.585 6.586C17.015 6.156 17.5 5.362 18.013 4.218C12.42 5.085 8.995 8.51 6.939 14.036H6.94ZM17 9.001L18 10C17 13 14 16 10 16.5C7.331 16.834 5.664 18.667 4.998 22H3C4 16 6 2 21 2C20 4.997 19.002 6.996 18.003 7.997L17 9.001Z"
                            fill="white"></path></g><defs><clipPath id="clip0_2181_24351"><rect width="24" height="24"
                                fill="white"></rect></clipPath></defs></svg></span>
                    <CategoryDetail>
                        <h1>Penulisan & Penerjemahan</h1>
                    </CategoryDetail>
                </Category>
                <Category onClick={() => navigate("/hustles?category=Admin dan Customer Service")}>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"><path
                        d="M20 15.002C19.9996 16.3696 19.5321 17.696 18.675 18.7616C17.8179 19.8273 16.6226 20.5683 15.287 20.862L14.649 18.948C15.2332 18.8518 15.7888 18.6271 16.2758 18.2903C16.7627 17.9534 17.1689 17.5128 17.465 17H15C14.4696 17 13.9609 16.7893 13.5858 16.4142C13.2107 16.0391 13 15.5304 13 15V11C13 10.4696 13.2107 9.96086 13.5858 9.58579C13.9609 9.21071 14.4696 9 15 9H17.938C17.694 7.0669 16.7529 5.28927 15.2914 4.00068C13.8299 2.71208 11.9484 2.00108 10 2.00108C8.05155 2.00108 6.17007 2.71208 4.70857 4.00068C3.24708 5.28927 2.30603 7.0669 2.062 9H5C5.53043 9 6.03914 9.21071 6.41421 9.58579C6.78929 9.96086 7 10.4696 7 11V15C7 15.5304 6.78929 16.0391 6.41421 16.4142C6.03914 16.7893 5.53043 17 5 17H2C1.46957 17 0.960859 16.7893 0.585786 16.4142C0.210714 16.0391 0 15.5304 0 15V10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10V15.002ZM18 15V11H15V15H18ZM2 11V15H5V11H2Z"
                        fill="white"></path></svg></span>
                    <CategoryDetail>
                        <h1>Admin & Customer Service</h1>
                    </CategoryDetail>
                </Category>
                <Category onClick={() => navigate("/hustles?category=Mengajar dan Joki")}>
                    <span><Fa.FaRegUser color={"#fff"} size={20} /></span>
                    <CategoryDetail>
                        <h1>Mengajar & Joki</h1>
                    </CategoryDetail>
                </Category>
            </CategoryGrid>
            <CardFooterCenter>
                <MoreCategory>Lebih Banyak</MoreCategory>
            </CardFooterCenter>
        </CategoryWrapper>);
}

export default Categories;