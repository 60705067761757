import { getAuthHeaders, getToken } from "./authService";
import httpService from "./httpService";

export async function createJob(body) {
    return httpService.post("/api/project", body, getAuthHeaders());
}

export async function getHustlesDashboard() {
    return httpService.get("/api/project/dashboard", getAuthHeaders());
}

export async function getHustles(filter) {
    const token = getToken();
    if (token !== null) {
        let url = `/api/project?`;
        for (const key in filter) {
            if (filter.hasOwnProperty(key)) url += `${key}=${filter[key]}`;
        }
        return httpService.get(url, getAuthHeaders())
    }
    let url = `project?`;
    for (const key in filter) {
        if (filter.hasOwnProperty(key)) url += `${key}=${filter[key]}`;
    }
    return httpService.get(url);
}

export async function getHustlerHustles() {
    return httpService.get("/api/project/apply/hustlers", getAuthHeaders());
}

export async function getJobDetail(uuid) {
    const token = getToken();
    if (token !== null) {
        return httpService.get(`/api/project/${uuid}`, getAuthHeaders())
    }
    return httpService.get(`/project/${uuid}`);
}

export async function applyJob(body) {
    return httpService.post("/api/project/apply", body, getAuthHeaders())
}

export async function getApplicants() {
    return httpService.get("/api/project/apply", getAuthHeaders());
}

export async function updateApplicant(body) {
    return httpService.put("/api/project/apply", body, getAuthHeaders())
}

export async function getHustlesApplicants(projID) {
    return httpService.get(`/api/project/applies?project_id=${projID}`, getAuthHeaders());
}