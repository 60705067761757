import styled from "styled-components";

export const CategoryWrapper = styled.div`
  padding: 3rem 2rem;
  @media screen and (min-width: 1024px) {
    padding: 2rem 10rem 6rem;
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  & > h1 {
    font-size: 34px;
    font-weight: 700;
  }

  & > p {
    font-size: 16px;
    line-height: 1.44;
    margin-top: 8px;
  }

  @media screen and (min-width: 1024px) {

    & > img {
      width: 325px;
      height: 433px;
    }
  }
`;

export const HeroTitle = styled.div`
  @media screen and (min-width: 1024px) {
    & > h1 {
      font-size: 26px;
    }

    & > p {
      margin-top: 1rem;
      font-size: 18px;
      line-height: 30px;
    }
  }
`;

export const CategoryGrid = styled.div`
  display: grid;
  gap: 2rem;

  @media screen and (min-width: 1024px) {
    margin-top: 2rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const Category = styled.div`
  cursor: pointer;
  padding: 24px;
  border: 1px solid #eee;
  border-radius: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  background: rgba(0, 116, 86, .05);
  gap: 1rem;

  &:hover {
    & > svg {
      transition: .5s ease;
      transform: scale(1.1);
    }
  }

  & > span {
    background: #007456;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
`;

export const CategoryDetail = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  & > h1 {
    font-size: 15.5px;
    font-weight: 500;
  }
`;

export const MoreCategory = styled.span`
  color: #088952;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;