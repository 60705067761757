import styled from "styled-components";

export const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    //border: 1px solid #ddd;
    padding: 16px 0 16px 24px;
    text-align: left;
    font-family: 'Inter', sans-serif;
  }

  td {
    font-weight: 400;
    font-size: 13px;
    color: #000;
    word-wrap: break-word;
    
    & > span {
      font-family: 'Inter';
      font-weight: 500;
    }
  }

  input {
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #ccc;
    padding: 4px;
  }

  tr:last-child {
    border: 0;
  }

  //tr:nth-child(even) {
  //  background-color: #f2f2f2;
  //}

  tr {
    border-bottom: 1px solid #eff0f2;
  }

  th {
    background-color: #f8f9fa;
    color: #545965;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 8px;
`;

export const CardWrapper = styled.div`
  padding: 0 12px;

  @media screen and (min-width: 1024px) {
    flex: 0 0 auto;
    width: 30%;
  }
`;


export const CardDashboard = styled.div`
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
`;

export const CardDashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardDashboardHeaderLeft = styled.div`
  & > h6 {
    font-size: 15px;
    color: #35415a;
    font-weight: 600;
  }

  & > span {
    font-size: 11px;
  }

  & > h4 {
    padding-top: .25rem;
    margin-top: 1rem;
    font-size: 22px;
    color: #35415a;
    font-weight: 600;
  }
`;

export const Avatar = styled.div`
  height: 3rem;
  width: 3rem;
`;

export const AvatarBody = styled.div`
  align-items: center;
  background-color: rgba(31, 88, 199, .25) !important;
  color: #fff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
  border-radius: .75rem;
`;

export const Card = styled.div`
  box-shadow: 0 3px 0 0 #e2e2e2;
  margin-bottom: 24px;
  background-color: #fff;
  border: 1px solid #eff0f2;
  border-radius: .6rem;
  background-clip: border-box;
  margin-top: 24px;
  width: 100%;
`;

export const CardBody = styled.div`
  padding: 1.5rem 1.5rem;
`;


export const FilterFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`;