import React from 'react';
import CurrencyFormat from "react-currency-format";
import { toast } from 'react-toastify';
import styled from "styled-components";
import { ButtonDashboard } from '../../../page/dashboard/style';
import { FileNameSpan } from '../../../page/job_detail/style';
import { createCommentRoom } from '../../../service/commentService';
import { updateApplicant } from '../../../service/jobService';
import { ApplicantFileWrapper } from '../applicants/style';
import { ModalWrapper } from "../auth/style";

export const ApplicantBidBody = styled.div`
  & > h1 {
    font-size: 14px;
    margin-bottom: 3px;
    font-weight: 700;
  }

  & > p, span {
    font-size: 13px;
    word-wrap: break-word;
  }
`;

const ApplicantBidModal = ({ applicant }) => (
  <ModalWrapper>
    <h1>Penawaran</h1>
    <ApplicantBidBody>
      <h1>Deskripsi</h1>
      <p>{applicant['project_apply_description']}</p>
      <h1>Portfolio</h1>
      <p>{applicant['portfolio']}</p>
      <h1>Penawaran Harga</h1>
      <p><CurrencyFormat value={applicant["bid"]} displayType={'text'}
        thousandSeparator={true}
        prefix={'Rp. '} /></p>
      <h1>File Pendukung</h1>
      {applicant['file_urls'].length > 0 ? <ApplicantFileWrapper style={{ marginTop: "3px" }}>
        {applicant['file_urls'].map((url, index) => <FileNameSpan onClick={() => window.open(url)} key={index}>{decodeURI(url.split("/")[6])}</FileNameSpan>)}
      </ApplicantFileWrapper> : <p>Tidak ada</p>}
      <h1>File Final</h1>
      {applicant['final_files'].length > 0 ? <ApplicantFileWrapper style={{ marginTop: "3px" }}>
        {applicant['final_files'].map((url, index) => <FileNameSpan onClick={() => window.open(url)} key={index}>{decodeURI(url.split("/")[7])}</FileNameSpan>)}
      </ApplicantFileWrapper> : <p>Belum dikirim</p>}
    </ApplicantBidBody>
    {applicant.applier_status === "Pending" && !applicant.is_conversation_start ? (<ButtonDashboard style={{ background: "#0a5f99", marginBottom: "8px" }} onClick={async () => {
      const response = window.confirm(`Yakin ingin memulai percakapan?`)
      if (response) {
        try {
          const { status } = await createCommentRoom({
            project_id: applicant['project_id'],
            type: "Project",
            target_id: applicant['applier_id'],
            target: applicant['applier_name']
          })
          if (status === 200) {
            toast.success("Percakapan dimulai");
            setTimeout(function () {
              window.location = "/dashboard/client/message";
            }, 1000)
          }
        } catch (e) {

        }
      }
    }}>
      <span>
        {applicant.applier_status === "Pending" ? "Mulai Obrolan" : null}
      </span>
    </ButtonDashboard>) : applicant.applier_status === "Pending" && applicant.is_conversation_start ? <ButtonDashboard style={{ background: "#0a5f99", marginBottom: "8px" }} onClick={() => {
      window.location = "/dashboard/client/message"
    }}>
      <span>Lihat Obrolan</span>
    </ButtonDashboard> : null}
    {applicant.applier_status !== "Approved" && applicant.applier_status !== "Finished" ? (<ButtonDashboard onClick={async () => {
      const response = window.confirm(`Yakin update project?`)
      if (response) {
        try {
          let applyStatus = 1;
          if (applicant.applier_status === "Submitted") {
            applyStatus = 4;
          }
          const { status } = await updateApplicant({
            project_apply_id: applicant['id'],
            status: applyStatus,
            project_id: applicant['project_id'],
            applier_id: applicant['applier_id']
          })
          if (status === 200) {
            toast.success("Berhasil update applicant");
            setTimeout(function () {
              window.location = "/dashboard/client/hustles";
            }, 1000)
          }
        } catch (e) {

        }
      }
    }}>
      <span>
        {applicant.applier_status === "Pending" ? "Terima Penawaran" : applicant.applier_status === "Submitted" ? "Selesaikan Project" : null}
      </span>
    </ButtonDashboard>) : null}
  </ModalWrapper>
);

export default ApplicantBidModal;