import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import * as Bi from 'react-icons/bi';
import { ModalComponent } from '../../../components/modal';
import { WithdrawForm } from '../../../components/modal/withdraw';
import Pagination from '../../../components/pagination';
import { FinishHustle } from '../../../form/finishHustle';
import { getHustlerHustles } from "../../../service/jobService";
import { paginate } from '../../../utils';
import { ColoringStatus, HustleStatus, MenuHeaderWrapper } from "../../dashboard/hustles/style";
import { DashboardContentWrapper } from "../../dashboard/style";
import { SubmitButton } from '../profile/profileBody/style';
import { Avatar, AvatarBody, Card, CardBody, CardDashboard, CardDashboardHeader, CardDashboardHeaderLeft, CardWrapper, FilterFlex, Row, StyledTable } from "../style";

const HustlesDH = (props) => {
    const [data, setData] = useState([]);
    const [remainingBalance, setRemainingBalance] = useState(0);
    const [futureBalance, setFutureBalance] = useState(0);
    const [totalData, setTotalData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedHustles, setPaginatedHustles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openFinishModal, setOpenFinishModal] = useState(false);
    const [selectedHustle, setSelectedHustle] = useState({});
    const pageSize = 10;

    const fetchHustles = async () => {
        const { status, data } = await getHustlerHustles();
        if (status === 200) {
            setData(data.data.applies);
            setTotalData(data.data.applies.length);
            const paginated = paginate(data.data.applies, currentPage, pageSize);
            setPaginatedHustles(paginated);
            setRemainingBalance(data.data['remaining_balance']);
            setFutureBalance(data.data['future_balance']);
        }
    }
    useEffect(() => {
        fetchHustles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        const paginatedHustles = paginate(data, page, pageSize);
        setPaginatedHustles(paginatedHustles);
    };

    return (
        <DashboardContentWrapper>
            <MenuHeaderWrapper style={{ marginBottom: "8px" }}>
                <h1>Daftar Hustlesmu</h1>
            </MenuHeaderWrapper>
            <Row>
                <CardWrapper style={{ padding: "0", marginTop: "0" }}>
                    <Card>
                        <CardDashboard>
                            <CardDashboardHeader>
                                <CardDashboardHeaderLeft>
                                    <h6>Saldo Aktif</h6>
                                    <h4><CurrencyFormat value={remainingBalance} displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'Rp. '} /></h4>
                                </CardDashboardHeaderLeft>
                                <Avatar>
                                    <AvatarBody>
                                        <Bi.BiMoney />
                                    </AvatarBody>
                                </Avatar>
                            </CardDashboardHeader>
                        </CardDashboard>
                    </Card>
                </CardWrapper>
                <CardWrapper>
                    <Card>
                        <CardDashboard>
                            <CardDashboardHeader>
                                <CardDashboardHeaderLeft>
                                    <h6>Saldo Tertunda</h6>
                                    <h4><CurrencyFormat value={futureBalance} displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'Rp. '} /></h4>
                                </CardDashboardHeaderLeft>
                                <Avatar>
                                    <AvatarBody>
                                        <Bi.BiMoney />
                                    </AvatarBody>
                                </Avatar>
                            </CardDashboardHeader>
                        </CardDashboard>
                    </Card>
                </CardWrapper>
            </Row>
            <Card style={{ marginTop: "0" }}>
                <CardBody>
                    <FilterFlex>
                        <SubmitButton onClick={() => setOpenModal(true)}>
                            Tarik Saldo
                        </SubmitButton>
                    </FilterFlex>
                    <StyledTable style={{ marginTop: "1rem" }}>
                        <colgroup>
                            <col style={{ width: "100px" }} />
                            <col style={{ width: "60px" }} />
                            <col style={{ width: "50px" }} />
                            <col style={{ width: "50px" }} />
                            <col style={{ width: "50px" }} />
                            <col style={{ width: "50px" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Judul</th>
                                <th>Bid</th>
                                <th>Status</th>
                                <th>Melamar Pada</th>
                                <th>Status Payment</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedHustles.map((apply, index) => (<tr key={index}>
                                <td>{apply['project_name']}</td>
                                <td><CurrencyFormat value={apply['bid']} displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'Rp. '} /></td>
                                <td><HustleStatus bg={ColoringStatus.get(apply['applier_status']).get('background')}
                                    color={ColoringStatus.get(apply['applier_status']).get('color')}>
                                    {apply['applier_status']}
                                </HustleStatus></td>
                                <td>{new Date(apply['applied_at']).toLocaleDateString()}</td>
                                <td>{apply['payment_status']}</td>
                                <td>{apply['applier_status'] === "Approved" ? <SubmitButton onClick={() => {
                                    setSelectedHustle(apply);
                                    setOpenFinishModal(true);
                                }}>
                                    Kirim File
                                </SubmitButton> : null}</td>
                            </tr>))}
                        </tbody>
                    </StyledTable>
                </CardBody>
            </Card>
            <Pagination
                itemsCount={totalData}
                onPageChange={handlePageChange}
                pageSize={pageSize}
            />
            <ModalComponent
                body={<WithdrawForm remainingBalance={remainingBalance} />}
                onHide={() => {
                    setOpenModal(!openModal)
                }}
                show={openModal}
                header={<Modal.Header>
                    <h1 style={{ fontSize: "16px", fontWeight: "700" }}>Penarikan Saldo</h1>
                </Modal.Header>}
            />
            <ModalComponent
                body={<FinishHustle id={selectedHustle['id']} title={selectedHustle['project_name']} />}
                onHide={() => {
                    setOpenFinishModal(!openFinishModal)
                }}
                show={openFinishModal}
                header={<Modal.Header>
                    <h1 style={{ fontSize: "16px", fontWeight: "700" }}>Kirim File Akhir</h1>
                </Modal.Header>}
            />
        </DashboardContentWrapper>
    );
}

export default HustlesDH;