import styled from "styled-components";

export const DashboardWrapper = styled.div`
  background: #fafafa;
`;

export const SideNavbar = styled.div`
  padding: 24px 32px;
  background-color: white;
  border-right: 1px solid #eee;
  position: fixed;
  width: 260px;
  z-index: 2;
  overflow-y: auto;
  height: 100%
`;

export const SideNavbarHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  & > img {
    width: 90px;
  }

  & > h1 {
    font-size: 15px;
  }
`;

export const SideNavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SideNavbarMenu = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-left: -18px;

  & > li {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;

    & > button {
      outline:  none;
      border: 0;
      background-color: transparent;
    }
    & > a, & > button {
      display: flex;
      align-items: center;
      padding: 8px 18px;
      border-radius: 30px;
      text-decoration: none;
      gap: 12px;
      transition: all .3s;

      &:hover {
        background: rgba(0, 116, 86, .05);

        & > span {
          & > img {
            filter: invert(14%) sepia(14%) saturate(3183%) hue-rotate(120deg) brightness(69%) contrast(100%);
          }

          color: #007456;
        }
      }

      & > span {
        min-width: 24px;
      }
    }
  }
`;

export const DashboardContent = styled.div`
  padding-left: 290px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const DashboardNavbar = styled.div`
  background: #fff;
  margin-left: -30px;
  margin-right: -30px;
  padding: 20px 30px;
  border-bottom: 1px solid #eee;

  & > img {
    max-width: 40px;
  }
`;

export const DashboardFlex = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DashboardFlexMenu = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  & > h1 {
    font-size: 14px;
  }
`;

export const ButtonDashboard = styled.button`
  background-color: #007456;
  border: 1px solid #007456;
  border-radius: 3em;
  padding: 5px 18px;

  & > span {
    font-weight: 500;
    color: #fff;
    font-size: 13px;
  }
`;

export const DashboardContentWrapper = styled.div`
  background-color: #fafafa;
`;

export const TableMenuDropdown = styled.ul`
  display: none;
  position: absolute;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .05);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .05);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .05);
  list-style: none;
  right: 35px;
  width: max-content;
  text-align: right;
  padding: 0;
  z-index: 2;
  margin-bottom: 0;

  & > li {
    padding: 12px 15px;
    font-size: 13.5px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .05);
    }
  }
`;