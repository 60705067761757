import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import { getCommentDetails, getComments, updateComment } from "../../../../service/commentService";
import { timeSince } from "../../../../utils";
import { ButtonActionMessage, CardMessage, CardMessageHeader, CardMessageWrapper, CommentAccountHeader, CommentContentHeader, CommentContentWrapper, CommentDetail, CommentListWrapper, CommentNameFlex, CommentWrapper, MessageAction, MessageClosed, MessageContentWrapper, MessageWrapper, TextAreaMessage, UnstyledListComment } from "./style";

export const CommentBody = () => {
    const [listComments, setListComments] = useState([]);
    const [selectedComments, setSelectedComments] = useState({
        uuid: ''
    });
    const [currComment, setCurrComment] = useState([])
    const [comment, setComment] = useState("");

    const fetchComments = async () => {
        try {
            const { status, data } = await getComments();
            if (status === 200) {
                setListComments(data.data);
            }
        } catch (e) {

        }
    }

    const fetchCommentDetails = async () => {
        try {
            let uuid = selectedComments['uuid'];
            const { status, data } = await getCommentDetails(uuid);
            if (status === 200) {
                setCurrComment(data.data);
            }
        } catch (e) {

        }
    }

    const onChange = ({ currentTarget: input }) => {
        setComment(input.value);
    }

    useEffect(() => {
        fetchComments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchCommentDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedComments])

    return (
        <CommentWrapper>
            <CommentListWrapper>
                {listComments.length > 0 ? <UnstyledListComment>
                    {listComments.map((comment, index) =>
                        <li key={index} onClick={() => {
                            setSelectedComments(comment);
                        }}>
                            <CommentDetail>
                                <CommentNameFlex>
                                    <h1>{comment['hustler_name']}</h1>
                                    <span>{timeSince(new Date(comment['created_at']))}</span>
                                </CommentNameFlex>
                                <p>Project: {comment['project_name']}</p>
                            </CommentDetail>
                        </li>)}
                </UnstyledListComment> : null}
            </CommentListWrapper>
            <CommentContentWrapper>
                {currComment.length > 0 ? <><CommentContentHeader>
                    <CommentAccountHeader>
                        <h1>{selectedComments['hustler_name']}</h1>
                        <span>Penawaran: <CurrencyFormat value={currComment[0]['bid'] || 0} displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Rp. '} /></span>
                    </CommentAccountHeader>
                </CommentContentHeader>
                    <MessageWrapper>
                        {currComment.length > 0 && currComment.map((comment, index) => <CardMessage key={index} direction={comment['displayed_as'] === "Sender" ? "row-reverse" : "row"}>
                            <CardMessageWrapper>
                                <CardMessageHeader direction={comment['displayed_as'] === "Sender" ? "row-reverse" : "row"}>
                                    <h1>{comment['sender']}</h1>
                                    <span>•</span>
                                    <span>{timeSince(new Date(comment['created_at']))}</span>
                                </CardMessageHeader>
                                <MessageContentWrapper background={comment['displayed_as'] === "Sender" ? "#007456" : "#f9f9f9"} color={comment['displayed_as'] === "Sender" ? "#fff" : "#555592"}>
                                    <p>{comment['text']}</p>
                                </MessageContentWrapper>
                            </CardMessageWrapper>
                        </CardMessage>)}
                    </MessageWrapper>
                    {selectedComments['status'] === 1 ? <MessageAction>
                        <TextAreaMessage onChange={onChange} />
                        <ButtonActionMessage onClick={async () => {
                            try {
                                let body = {
                                    uuid: selectedComments['uuid'],
                                    content: comment
                                }
                                const { status } = await updateComment(body);
                                if (status === 200) {
                                    toast.success("Sukses kirim");
                                    setTimeout(function () {
                                        window.location = window.location.href;
                                    }, 900)
                                }
                            } catch (e) {

                            }
                        }}>
                            Kirim
                        </ButtonActionMessage>
                    </MessageAction> : <MessageAction>
                        <MessageClosed>
                            <p>Percakapan telah ditutup</p>
                        </MessageClosed>
                    </MessageAction>}

                </> : null}
                {/* {currComment.length > 0 && currComment.map((comment, index) => <CommentContentHeader>
                    <CommentAccountHeader>
                        <h1>{comment['target']}</h1>
                    </CommentAccountHeader>
                </CommentContentHeader>)} */}
            </CommentContentWrapper>
        </CommentWrapper>
    )
}