import Lottie from "lottie-react";
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import loading from "../../assets/lottie/activation.json";
import { activation } from "../../service/authService";

export const ActivationPage = () => {
    const [searchParams] = useSearchParams();

    const activate = async () => {
        const { status, data } = await activation(searchParams.get("email"), searchParams.get("otp"))
        if (status === 200) {
            setTimeout(function () {
                localStorage.setItem("token", data['token']);
                window.location = "/"
            }, 1000)
        }
    }

    useEffect(() => {
        activate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<Lottie animationData={loading} />
    );
}
