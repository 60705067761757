import React, {Component} from 'react';

export const DataContext = React.createContext();

class DataProvider extends Component {
    state = {
        email: "",
        isRegistering: false,
        showDesc: false,
        showApplicants: false,
        isClosed: true,
    }

    setEmail = (email) => {
        this.setState({email});
    };

    setIsRegistering = (isRegistering) => {
        this.setState({isRegistering})
    }

    setShowDesc = () => {
        const showDesc = !this.state.showDesc;
        this.setState({showDesc})
    }

    setShowApplicants = () => {
        const showApplicants = !this.state.showApplicants;
        this.setState({showApplicants})
    }

    setIsClosed = () => {
        const isClosed = !this.state.isClosed;
        this.setState({isClosed})
    }

    render() {
        const {email, isRegistering, showDesc, showApplicants, isClosed} = this.state;
        const {setEmail, setIsRegistering, setShowDesc, setShowApplicants, setIsClosed} = this;
        return (
            <DataContext.Provider
                value={{
                    email,
                    setEmail,
                    isRegistering,
                    setIsRegistering,
                    showDesc,
                    setShowDesc,
                    showApplicants,
                    setShowApplicants,
                    isClosed,
                    setIsClosed
                }}
            >
                {this.props.children}
            </DataContext.Provider>
        );
    }
}

export default DataProvider;