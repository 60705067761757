import React, { useContext, useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import Select from 'react-select';
import { DataContext } from "../../../components/context";
import { ModalComponent } from "../../../components/modal";
import ApplicantBidModal from "../../../components/modal/applicantsBid";
import Pagination from '../../../components/pagination';
import { TableTd } from '../../../components/table/tableBody/tableBodyElements';
import { getApplicants } from "../../../service/jobService";
import { paginate } from '../../../utils';
import { selectStyles } from '../../dashboardHustlers/profile/profileBody';
import { FilterFlex, StyledTable } from '../../dashboardHustlers/style';
import { ColoringStatus, HustleStatus, MenuHeaderWrapper } from "../hustles/style";
import { SideNavigationBarClient } from '../sidenavbar';
import { ButtonDashboard, DashboardContentWrapper } from "../style";
import { DashboardClientWrapper } from '../wrapper';

export const Status = [
    {
        value: "Pending",
        label: "Pending"
    },
    {
        value: "Approved",
        label: "Approved"
    },
    {
        value: "Submitted",
        label: "Submitted"
    },
]

const Applicants = () => {
    const [selectedApplicant, setSelectedApplicant] = useState({});
    // const [filteredApplicants, setFilteredApplicants] = useState([]);
    const [applicants, setApplicants] = useState([]);
    const [paginatedApplicants, setPaginatedApplicants] = useState([]);
    const { showDesc, setShowDesc } = useContext(DataContext);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const fetchApplicants = async () => {
        try {
            const { status, data } = await getApplicants();
            if (status === 200) {
                setApplicants(data.data);
                // setFilteredApplicants(data.data);
                const paginated = paginate(data.data, currentPage, pageSize);
                setPaginatedApplicants(paginated);
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        fetchApplicants()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setCategory = (value) => {
        if (value) {
            const filtered = applicants.filter(applicant => applicant.applier_status === value.value);
            const paginated = paginate(filtered, currentPage, pageSize);
            setPaginatedApplicants(paginated);
        } else {
            const paginated = paginate(applicants, currentPage, pageSize);
            setPaginatedApplicants(paginated);
        }
    }

    return (
        <DashboardContentWrapper>
            <SideNavigationBarClient />
            <DashboardClientWrapper
                child={<>
                    <MenuHeaderWrapper>
                        <h1>Pelamar</h1>
                    </MenuHeaderWrapper>
                    <FilterFlex style={{ marginBottom: "1rem" }}>
                        <Select
                            options={Status}
                            isClearable={true}
                            isSearchable={true}
                            styles={selectStyles}
                            onChange={setCategory}
                        />
                    </FilterFlex>
                    <StyledTable>
                        <colgroup>
                            <col style={{ width: "230px" }} />
                            <col style={{ width: "120px" }} />
                            <col style={{ width: "130px" }} />
                            <col style={{ width: "90px" }} />
                            <col style={{ width: "80px" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Status</th>
                                <th>Information</th>
                                <th>Bid</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedApplicants.map((apply, index) =>
                                <tr key={index}>
                                    <TableTd>
                                        <h1>{apply['applier_name']}</h1>
                                        <p>Applied: <span
                                            style={{
                                                fontWeight: "500",
                                                textDecoration: "underline"
                                            }}>{apply['project_name'].length > 40 ? apply['project_name'].substring(0, 40) + "..." : apply['project_name']}</span>
                                        </p>
                                    </TableTd>
                                    <TableTd>
                                        <HustleStatus bg={ColoringStatus.get(apply['applier_status']).get('background')}
                                            color={ColoringStatus.get(apply['applier_status']).get('color')}>
                                            {apply['applier_status']}
                                        </HustleStatus>
                                        <p>Applied: {new Date(apply['applied_at']).toLocaleDateString()}</p>
                                    </TableTd>
                                    <TableTd>
                                        <p>{apply['applier_email']}</p>
                                        <p>{apply['applier_phone_number']}</p>
                                    </TableTd>
                                    <TableTd>
                                        <span>
                                            <CurrencyFormat value={apply["bid"]} displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'Rp. '} />
                                        </span>
                                    </TableTd>
                                    <TableTd>
                                        <ButtonDashboard onClick={() => {
                                            setSelectedApplicant(apply);
                                            setShowDesc(true);
                                        }}>
                                            <span>Detail</span>
                                        </ButtonDashboard>
                                    </TableTd>
                                </tr>
                            )}
                        </tbody>
                    </StyledTable>
                    <div style={{ marginTop: "1rem" }}>
                        <Pagination
                            itemsCount={applicants.length}
                            pageSize={pageSize}
                            onPageChange={(page) => {
                                setCurrentPage(page);
                                const paginated = paginate(applicants, page, pageSize)
                                setPaginatedApplicants(paginated);
                            }}
                        />
                    </div>
                    <ModalComponent
                        show={showDesc}
                        onHide={setShowDesc}
                        body={<ApplicantBidModal applicant={selectedApplicant} />}
                    />
                </>}
            />
        </DashboardContentWrapper>
    );
}

export default Applicants;