import React, { useEffect, useState } from 'react';
import featured from "../../../assets/icons/dashboard/table/icon-featured.svg";
import urgent from '../../../assets/icons/dashboard/table/icon-urgent.svg';
import { ModalComponent } from "../../../components/modal";
import Applicants from "../../../components/modal/applicants";
import Pagination from '../../../components/pagination';
import { TableTd, TableTdHeader } from '../../../components/table/tableBody/tableBodyElements';
import { getHustlesDashboard } from "../../../service/jobService";
import { paginate } from '../../../utils';
import { StyledTable } from '../../dashboardHustlers/style';
import { SideNavigationBarClient } from '../sidenavbar';
import { ButtonDashboard, DashboardContentWrapper } from "../style";
import { DashboardClientWrapper } from '../wrapper';
import { ColoringStatus, FilterWrapper, HustleStatus, MenuHeaderWrapper } from "./style";

const Hustles = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState([]);
    const [showApplicants, setShowApplicants] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const pageSize = 5;

    const fetchHustles = async () => {
        const { data: resp } = await getHustlesDashboard();
        const data = resp.data;
        setData(data);
        const paginated = paginate(data, currentPage, pageSize);
        setPaginatedData(paginated);
    }

    useEffect(() => {
        fetchHustles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSearch = ({ currentTarget }) => {
        setCurrentPage(1);

        const filtered = data.filter(hustle => hustle.job_title.toLowerCase().includes(currentTarget.value.toLowerCase()));
        const paginated = paginate(filtered, currentPage, pageSize);
        setPaginatedData(paginated);
    }

    return (<DashboardContentWrapper>
        <SideNavigationBarClient />
        <DashboardClientWrapper
            child={<>
                <MenuHeaderWrapper>
                    <h1>Side Hustlemu</h1>
                </MenuHeaderWrapper>
                <FilterWrapper>
                    <input type="text" name="" id="" placeholder="Cari Side Hustle" onChange={handleSearch} />
                </FilterWrapper>
                <StyledTable>
                    <colgroup>
                        <col style={{ width: "200px" }} />
                        <col style={{ width: "60px" }} />
                        <col style={{ width: "100px" }} />
                        <col style={{ width: "70px" }} />
                        <col style={{ width: "80px" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Judul</th>
                            <th>Pelamar</th>
                            <th>Status</th>
                            <th>Deadline</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((item, index) => <tr key={index}>
                            <TableTd>
                                <TableTdHeader>
                                    {item['is_featured'] ? (<img src={featured} alt="" />) : null}
                                    {item['is_urgent'] ? (<img src={urgent} alt="" />) : null}
                                    <h1>{item['job_title']}</h1>
                                </TableTdHeader>
                                <p>{item['category']}</p>
                            </TableTd>
                            <TableTd>
                                {item['total_bidder']}
                            </TableTd>
                            <TableTd>
                                <HustleStatus bg={ColoringStatus.get(item['job_status']).get('background')}
                                    color={ColoringStatus.get(item['job_status']).get('color')}>
                                    {item['job_status']}
                                </HustleStatus>
                                <p>Dibuat pada: {new Date(item['created_at']).toLocaleDateString()}</p>
                            </TableTd>
                            <TableTd>
                                {new Date(item['deadline']).toLocaleDateString()}
                            </TableTd>
                            <TableTd>
                                <ButtonDashboard onClick={() => {
                                    setSelectedData(item);
                                    setShowApplicants(true);
                                }}>
                                    <span>Detail</span>
                                </ButtonDashboard>
                            </TableTd>
                        </tr>)}
                    </tbody>
                </StyledTable>
                <div style={{ marginTop: "1rem" }}>
                    <Pagination
                        itemsCount={data.length}
                        pageSize={pageSize}
                        onPageChange={(page) => {
                            setCurrentPage(page);
                            const paginated = paginate(data, page, pageSize);
                            setPaginatedData(paginated);
                        }}
                    />
                </div>
                <ModalComponent
                    size="xl"
                    show={showApplicants}
                    onHide={() => {
                        setShowApplicants(!showApplicants)
                    }}
                    body={<Applicants projectID={selectedData['id']} uuid={selectedData['uuid']} />}
                />
            </>}
        />
    </DashboardContentWrapper>)
}

export default Hustles;
