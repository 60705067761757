import React from 'react';
import {ModalWrapper} from "../auth/style";

const RegisterDone = ({email}) => (
    <ModalWrapper>
        <h1>Please Check Your Email 😊</h1>
        <p style={{textAlign: "center"}}>
            Klik tautan yang kami kirim ke {email} dan kamu selangkah lagi menjadi bagian dari Hustlers. Jika tidak ada
            di inbox, coba cek di spam ya!
        </p>
    </ModalWrapper>
);

export default RegisterDone;