import React from "react";
import { FormComponentWrapper, FormInputFile, FormLabelFile } from "../style";

const InputFile = ({ error, name, label, description, ...rest }) => {
    return (
        <FormComponentWrapper>
            <FormLabelFile htmlFor={name}>{label}</FormLabelFile>
            {description && <span style={{ margin: "0" }}>{description}</span>}
            <FormInputFile {...rest} id={name} name={name} />
            {error && <div className="alert alert-danger">{error}</div>}
        </FormComponentWrapper>
    );
};

export default InputFile;
