import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  & > h1 {
    font-size: 18px;
    margin-bottom: 2rem;
    font-weight: 700;
  }
`;
