import styled from "styled-components";

export const ColoringStatus = new Map([
  ["Opening", new Map([
    ["background", "#e8f7f5"],
    ["color", "#13af97"]
  ])],
  ["Approved", new Map([
    ["background", "#e8f7f5"],
    ["color", "#13af97"]
  ])],
  ["Pending", new Map([
    ["background", "#fff9e7"],
    ["color", "#ffc402"]
  ])],
  ["Closed", new Map([
    ["background", "#eee"],
    ["color", "#999"]
  ])],
  ["Rejected", new Map([
    ["background", "#eee"],
    ["color", "#999"]
  ])],
  ["Submitted", new Map([
    ["background", "#e8f7f5"],
    ["color", "#13af97"]
  ])],
  ["Finished", new Map([
    ["background", "#e8f7f5"],
    ["color", "#13af97"]
  ])],
  ["Unpaid", new Map([
    ["background", "#fff9e7"],
    ["color", "#ffc402"]
  ])],
  ["Expired", new Map([
    ["background", "#eee"],
    ["color", "#999"]
  ])],
  ["Paid", new Map([
    ["background", "#e8f7f5"],
    ["color", "#13af97"]
  ])],
])

export const MenuHeaderWrapper = styled.div`
  margin: 34px 0;

  & > h1 {
    font-size: 21px;
    color: #111111;
    font-weight: 700;
  }
`;

export const MenuHeaderFlex = styled.div`
  display: flex;
  justify-content: space-between;
  
  & > h1 {
    font-size: 21px;
    color: #111111;
    font-weight: 700;
  }
`;

export const HustleStatus = styled.label`
  padding: 5px 13px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};
  font-family: 'Inter';
  font-weight: 600;
`;

export const FilterWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;

  & > input {
    font-size: 14px;
    border: 1px solid #ddd;
    padding: 10.5px 16px;
    color: #555555;
    border-radius: 4px;
    background-color: #fff;
    transition: all .3s ease 0s;
  }
`;