import styled from "styled-components";

export const ApplicantFileWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 14px;
        margin-bottom: .5rem;
    }
`;