import styled from "styled-components";

export const NavbarWrapper = styled.div`
  background: white;
  padding: 4px 2rem;
  border-bottom: 1px solid #e6e6e6;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;

  @media screen and (min-width: 1024px) {
    padding: 4px 6rem;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavbarFlexWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  & > a >img {
    width: 85px;
  }
  & > span {
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
  }
`;

export const NavbarMenu = styled.div`
  display: none;
  gap: 1.5rem;
  align-items: center;

  & > span {
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

export const NavbarDropdown = styled.div`
  position: absolute;
  top: 10px;
  right: 100px;
  z-index: 4;
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  visibility: ${({ isOpen }) => isOpen ? "visible" : "hidden"};
  padding-top: 30px;
  min-width: 215px;
  -webkit-transition: all .3s ease 0s;
  -moz-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  transition: all .3s ease 0s
`;

export const NavbarDropdownContainer = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const ListNavDashboard = styled.ul`
  padding: 20px;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > li {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
  }
`;

export const NavbarAuthContainer = styled.div`
  padding: 5px;
  cursor: pointer;

  & > span {
    font-size: 13px;
    font-weight: 500;
  }

  & > img {
    width: 25px;
    margin-right: 10px;
  }
`;