import React from 'react';
import Navbar from "../../section/navbar";
import { JobDetailWrapper } from "../job_detail/style";
import { CommonWrapper, TncWrapper } from "./style";

const TermsCondition = (props) => (
    <>
        <Navbar />
        <JobDetailWrapper>
            <CommonWrapper>
                <h1>Syarat dan Ketentuan</h1>
                <TncWrapper>
                    <h1>Pendahuluan</h1>
                    <p>Layanan ini disediakan oleh Hustlers yang beralamat di Apartemen Dlofts G09, Palmerah, Jakarta
                        Barat.</p>
                    <p>Dengan mendaftar dan menggunakan layanan kami, Anda menyatakan bahwa
                        Anda telah membaca, menerima, dan bersedia untuk terikat pada Perjanjian/Persyaratan Pengguna
                        ini. Harap
                        diperhatikan bahwa jika Pengguna tidak menerima Perjanjian Pengguna ini secara utuh, maka
                        Pengguna tidak dapat mengakses atau menggunakan platform Hustlers. Perjanjian Pengguna ini
                        berisikan syarat-syarat yang menjelaskan bagi Anda dalam mengakses dan menggunakan layanan pada
                        platform Hustlers.</p>
                    <p>Dengan menggunakan situs ini, berarti Anda dianggap telah membaca, menyetujui dan menerima semua
                        Syarat
                        dan Ketentuan dalam Perjanjian
                        Pengguna yang berada di platform Hustlers. Hustlers dapat mengubah, mengganti, memodifikasi,
                        menambah atau menghapus setiap bagian Perjanjian Pengguna, baik secara keseluruhan maupun
                        sebagian apabila diperlukan. Hustlers akan mengirimkan pemberitahuan modifikasi Perjanjian
                        Pengguna ke alamat email Anda jika diperlukan.</p>
                </TncWrapper>
                <TncWrapper>
                    <h1>1. Tentang Hustlers</h1>
                    <p>Platform Hustlers membantu para penyedia jasa professional ("Freelancer" dan / atau disebut
                        dengan "Hustler") untuk mencari pekerjaan sampingan (Side Hustle) dengan para Klien atau
                        penyedia kerja sampingan.
                    </p>
                    <p>Freelancer atau Hustlers dapat mencari pekerjaan sampingan yang tersedia, mengajukan tawaran, dan
                        melamar pekerjaan. Klien atau penyedia pekerjaan sampingan dapat mengirimkan lowongan pekerjaan
                        sampingan dan memilih kandidat yang sesuai. Jika Klien dan
                        Hustlers setuju dengan persyaratan yang diajukan oleh masing-masing pihak, segera setelah
                        Klien melakukan pembayaran kepada Hustlers (aturan mengenai down payment klien juga diatur di
                        peraturan pengguna ini), maka Klien dan Freelancer dapat berkomunikasi dan
                        Freelancer dapat mulai mengerjakan pekerjaan tersebut, dan harus tunduk pada ketentuan yang
                        diatur dalam Perjanjian Pengguna.</p>
                </TncWrapper>
                <TncWrapper>
                    <h1>2. Kebijakan Umum Pengguna</h1>
                    <ul>
                        <li><p>
                            Segala bentuk komunikasi di luar platform Hustlers antara pihak Klien (penyedia pekerjaan
                            sampingan) dengan Hustler (penyedia jasa professional) akan dianggap sebuah pelanggaran
                            terhadap Perjanjian Pengguna ini. Hustlers sebagai penyedia platform tidak bertanggung jawab
                            atas segala kerugian yang terjadi.
                        </p></li>
                        <li><p>
                            Anda diwajibkan untuk bertanggung jawab atas setiap informasi yang diberikan dan ditampilkan
                            pada platform Hustlers. Hustlers memiliki hak untuk memperbarui dan mengubah informasi
                            pengguna jika ditemukan adanya informasi yang tidak sesuai, tidak
                            akurat, atau menyesatkan.
                        </p></li>
                        <li>
                            <p>Pengguna setuju untuk menjaga kerahasiaan kata sandi akun, dan tidak akan memberitahukan
                                kepada siapapun, serta bertanggung jawab atas akun Anda sendiri.</p>
                        </li>
                        <li><p>Untuk kepentingan informasi yang terkini, Pengguna diharuskan untuk memberikan informasi
                            termasuk memberikan Nomor Pokok Wajib Pajak
                            maupun Nomor Identitas Kependudukan, guna kepentingan validasi identitas pengguna.</p></li>
                        <li><h1>Pembayaran</h1>
                            <ol>
                                <li><p>Jika kami (Hustlers) tidak menerima sejumlah uang pembayaran dari Klien yang
                                    diperlukan
                                    untuk
                                    proses berikutnya, maka Freelancer/Hustler tidak dapat dan tidak diwajibkan untuk
                                    mengerjakan
                                    pekerjaan
                                    sampingan.</p></li>
                                <li><p>Pembayaran kepada pihak Freelancer/Hustler akan dilakukan segera setelah
                                    Freelancer/Hustler menyelesaikan pekerjaan, melakukan submit file yang diperlukan
                                    dan
                                    mengajukan permohonan pembayaran.</p></li>
                            </ol>
                        </li>
                        <li><p>Pengguna setuju dalam berpartisipasi menjaga ekosistem komunitas Hustlers dengan tidak
                            memberikan informasi palsu, tidak valid atau berbahaya, termasuk melampirkan file yang tidak
                            semestinya, atau memberikan baik situs maupun tautan yang dapat membahayakan pengguna lain
                            dan sistem Hustlers.</p></li>
                    </ul>
                </TncWrapper>
                <TncWrapper>
                    <h1>3. Aturan dan Batasan bagi Klien dan Hustler</h1>
                    <ul>
                        <li>
                            <h1>Pembayaran</h1>
                            <ol>
                                <li>
                                    <p>Freelancer/Hustler tidak diwajibkan untuk mengerjakan pekerjaan sampingan apabila
                                        Klien (penyedia pekerjaan sampingan) tidak membayarkan sejumlah uang yang
                                        diperlukan kepada kami (Hustlers). Peraturan mengenai ketetapan pembayaran
                                        pekerjaan sampingan akan diatur di poin bawah.</p>
                                </li>
                                <li><p>Freelancer/Hustler setuju bahwa pembayaran hanya dilakukan oleh dan melalui pihak
                                    Hustlers,
                                    dan
                                    tidak akan meminta pembayaran langsung kepada pihak Klien.</p>
                                </li>
                            </ol>
                        </li>
                        <li><p>Baik Klien maupun Hustler setuju bahwa tidak akan memposting, melampirkan atau memberikan
                            pekerjaan yang berkaitan dengan</p>
                            <ol>
                                <li><p>
                                    Hal-hal yang berlawanan dengan norma dan hukum seperti: Obat-obatan terlarang atau
                                    Narkoba, Judi, Pornografi, Korupsi, dll.
                                </p></li>
                                <li><p>Segala hal yang berkaitan dengan agama ataupun ajaran agama</p></li>
                                <li><p>Segala hal yang mendiskreditkan atau menyerang Suku, Agama, Ras, Antar Golongan
                                    (SARA)</p></li>
                                <li><p>Jasa money game, joki slot, asuransi, MLM, atau investasi</p></li>
                                <li><p>Segala sesuatu yang berkaitan dengan terorisme</p></li>
                                <li><p>Jasa penggalangan dana, perdagangan manusia, pembuatan senjata dalam bentuk
                                    apapun</p></li>
                                <li><p>Hacking atau Phising</p></li>
                                <li><p>Segala sesuatu yang berkaitan dengan dunia magis, seperti menawarkan jasa
                                    cenayang, santet, atau hal-hal yang dapat membahayakan orang lain.</p></li>
                                <li><p>Jasa biro jodoh, hukum, pembuatan ijin usaha</p></li>
                            </ol>
                        </li>
                    </ul>
                    <ul>
                        <li><h1>Pekerjaan</h1>
                            <ol>
                                <li><p>Freelancer/Hustler wajib mengerjakan pekerjaan sesuai dengan yang disepakati
                                    dalam
                                    tenggang waktu maksimal 14 hari setelah pembayaran diterima oleh Hustlers.</p></li>
                                <li><p>Hak Kekayaan Intelektual atas Hasil Kerja yang sudah disubmit akan dimiliki oleh
                                    Klien setelah
                                    pekerjaan selesai.</p></li>
                            </ol>
                        </li>
                    </ul>
                </TncWrapper>
            </CommonWrapper>
        </JobDetailWrapper>
    </>
);

export default TermsCondition;