import { convertToHTML } from "draft-convert";
import TimeAgo from "javascript-time-ago";
import _ from "lodash";

// English.
import en from "javascript-time-ago/locale/id.json";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("id-ID");

export function timeSince(date) {
    const response = timeAgo.format(date);
    return response;
}

export const draftToHTML = convertToHTML({
    //eslint-disable-next-line react/display-name
    styleToHTML: (style) => {
        if (style === "STRIKETHROUGH") {
            return <span style={{ textDecoration: "line-through" }} />;
        }
    },
    entityToHTML: (entity, originalText) => {
        if (entity.type === "image" || entity.type === "IMAGE") {
            let classNames = "draft-image ";
            if (entity.data.alignment) {
                classNames = classNames + entity.data.alignment;
            }
            let style = "width:" + (entity.data.width || 40) + "%";
            return `<figure><img src="${entity.data.src}" class="${classNames}" style="${style}" width="100%"/></figure>`;
        }
        if (entity.type === "LINK") {
            return {
                start: `<a href="${entity.data.url || entity.data.href}">`,
                end: "</a>",
            };
        }
        if (entity.type === "IMG") {
            const className = "draft-inline-image";
            return `<img src="${entity.data.src}" class="${className}" alt="${entity.data.alt}" width="100%"/>`;
        }
        if (entity.type === "INLINETEX") {
            if (entity.data.html) {
                return `<span>${entity.data.css ? `<style>${entity.data.css}</style>` : ""
                    }${entity.data.html}</span>`;
            } else {
                return `<span class="draft-latex-placeholder"> &lt; refresh to render LaTeX &gt; </span>`;
            }
        }
        return originalText;
    },
    //eslint-disable-next-line react/display-name
    blockToHTML: (block) => {
        const type = block.type;
        if (type === "atomic") {
            if (block.data && block.data.mathjax && block.data.html) {
                return `<div>${block.data.css ? `<style>${block.data.css}</style>` : ""
                    }${block.data.html}</div>`;
            } else if (block.data && block.data.mathjax) {
                return `<div class="draft-latex-placeholder-block"> &lt;refresh to render LaTeX&gt; </div>`;
            } else {
                return { start: "<span>", end: "</span>" };
            }
        }
        if (type === "blockquote") {
            return <blockquote />;
        }
        if (type === "code") {
            return {
                start: '<pre style="background-color: #eee; padding: 1rem"><code>',
                end: "</code></pre>",
            };
        }
        if (type === "divider") {
            return <hr className="dividerBlock" />;
        }
        if (type === "spoiler") {
            return <p className="spoiler" />;
        }
        if (type === "unstyled") {
            if (block.text === " " || block.text === "") return <br />;
            return <p />;
        }
        //  return <span/>;
    },
});

export const dayLefts = (endTime) => {
    let now = new Date().getTime();
    return ((new Date(endTime).getTime() - now) / (1000 * 3600 * 24)).toFixed(0);
}

export function paginate(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize;
    //_.slice(items, startIndex);
    return _(items).slice(startIndex).take(pageSize).value();
}