import React from "react";
import home from "../../assets/icons/dashboard/sidenavbar/home.svg";
import job from "../../assets/icons/dashboard/sidenavbar/job.svg";
import pkg from "../../assets/icons/dashboard/sidenavbar/package.svg";
import profile from "../../assets/icons/dashboard/sidenavbar/profile.svg";
import logout from "../../assets/icons/logout.svg";
import message from "../../assets/icons/message.svg";
import logo from "../../assets/images/logotext.png";
import { SideNavbar, SideNavbarContainer, SideNavbarHeader, SideNavbarMenu } from "../../page/dashboard/style";
import { logOut } from "../../service/authService";

export const SideNavbarHustler = () => {
    return (
        <SideNavbar>
            <SideNavbarContainer>
                <SideNavbarHeader>
                    <img src={logo} alt="" onClick={() => window.location = "/"} />
                </SideNavbarHeader>
                <SideNavbarMenu>
                    <li><a href="/">
                        <span><img src={home} alt="" /></span>
                        <span>Home</span>
                    </a></li>
                    <li><a href="/dashboard/hustler/hustles">
                        <span><img src={job} alt="" /></span>
                        <span>Hustles</span>
                    </a></li>
                    <li><a href="/dashboard/hustler/profile">
                        <span><img src={profile} alt="" /></span>
                        <span>Profile</span>
                    </a></li>
                    <li><a href="/dashboard/hustler/transactions">
                        <span><img src={pkg} alt="" /></span>
                        <span>Transactions</span>
                    </a></li>
                    <li><a href="/dashboard/hustler/message">
                        <span><img src={message} alt="" /></span>
                        <span>Message</span>
                    </a></li>
                    <li><button href="#" onClick={async () => {
                        const { status } = await logOut();
                        if (status === 200) {
                            localStorage.removeItem("token");
                            setTimeout(function () {
                                window.location = "/"
                            }, 500)
                        }
                    }}>
                        <span><img src={logout} alt="" /></span>
                        <span>Logout</span>
                    </button></li>
                </SideNavbarMenu>
            </SideNavbarContainer>
        </SideNavbar>
    )
}