import styled from "styled-components";

export const ProfileSectionWrapper = styled.div`
    & > h1 {
        font-size: 18px;
        font-weight: 700;
    }
`;

export const ProfileDataContainer = styled.div` 
    flex: 1;

    & > p {
        font-size: 13px;
        font-family: 'Inter', sans-serif;
    }

    & > span {
        color: #999;
    }
`;

export const ProfileDataFlex = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: 2rem;
    margin: 1.5rem 0;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

export const ProfileInputWrapper = styled.input`
    font-size: 13px;
    border: 1px solid #ddd;
    padding: 10.5px 16px;
    color: #555555;
    border-radius: 4px;
    width: 100%;
    background-color: #fff;

    &:disabled {
        background-color: #eee;
    }
`;

export const FileUploader = styled.label`
    padding: 10px 16px;
    border-radius: 8px;
    background: #0a5f99;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 13px;
`;

export const SubmitButton = styled.button`
    padding: 7px 16px;
    border-radius: 8px;
    background: #0a5f99;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 13px;
`;

export const FileImageWrapper = styled.div` 
    margin-bottom: 1rem;
    & > img {
        width: 500px;
    }
`;

export const WarningWrapper = styled.div`
    background-color: #FEE;
    border: 1px solid #EDD;
    color: #A66;
    padding: 12px 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
`;

export const WarningOrangeWrapper = styled.div`
    background-color: #FDF7DF;
    border: 1px solid #FEEC6F;
    color: #C9971C;
    padding: 12px 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-size: 13px;
`;