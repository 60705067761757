import React from 'react';
import Categories from "../../section/categories";
import Footer from "../../section/footer";
import Hero from "../../section/hero";
import Hook from "../../section/hook";
import How from "../../section/how";
import Navbar from "../../section/navbar";

const Main = () => (
    <>
        <Navbar />
        <Hero />
        <How />
        <Categories />
        <Hook />
        <Footer />
    </>
);

export default Main;