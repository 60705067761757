import styled from "styled-components";

export const CommentWrapper = styled.div`
    border: 1px solid #eee;
    border-radius: 16px;
    display: flex;
    background-color: #fff;
    min-height: 500px;
`

export const CommentListWrapper = styled.div`
    flex: 0 0 370px;
    border-right: 1px solid #eee;
`;

export const CommentContentWrapper = styled.div`
    width: 100%;
    flex: 1;
`;

export const CommentListTabContent = styled.div`
    height: 500px;
    overflow: auto;
`;

export const UnstyledListComment = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
        display: flex;
        color: #999;
        cursor: pointer;

        &:hover {
            background-color: rgba(0,116,86,.05);
        }
    }
`;

export const CommentDetail = styled.div`
    width: 100%;
    padding: 16px 0;
    margin: 0 32px;
    border-bottom: 1px solid #eee;
    & > p {
        margin-top: 12px;
        margin-bottom: 0;
        font-size: 13px;
    }
`;

export const CommentNameFlex = styled.div`
    display: flex;
    justify-content: space-between;

    & > h1 {
        color: #111111;
        font-weight: 700;
        font-size: 15px;
    }

    & > span {
        font-size: 13px;
    }
`;

export const CommentContentHeader = styled.div`
    padding: 24px 32px;
    display: flex;
    border-bottom: 1px solid #eee;
`;

export const CommentAccountHeader = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 16px;
        color: #111;
        font-weight: 600;
    }

    & > span {
        font-size: 13px;
        margin-top: 3px;
    }
`;

export const MessageWrapper = styled.div`
    padding: 32px;
    overflow: auto;
    height: 350px;
`;

export const CardMessage = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction ? direction : "row"};
    margin-bottom: 1rem;
`;

export const CardMessageWrapper = styled.div`
    max-width: 320px;
`;

export const CardMessageHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 0;
    gap: 8px;
    flex-direction: ${({ direction }) => direction};

    & > h1 {
        color: #111;
        font-size: 13px;
    }

    & > span {
        font-size: 13px;
        color: #999;
    }
`;

export const MessageContentWrapper = styled.div`
    padding: 16px 24px;
    background: ${({ background }) => background};
    border-radius: 8px;
    margin-top: 5px;

    & > p {
        margin: 0;
        color: ${({ color }) => color};
        font-size: 13px;
    }
`;

export const MessageAction = styled.div`
    border-top: 1px solid #eee;
    position: relative;
`;

export const MessageClosed = styled.div`
    padding: 32px;

    & > p {
        margin: 0;
    }
`;

export const TextAreaMessage = styled.textarea`
    padding: 45px 110px 45px 32px;
    height: 120px;
    border: 0;
    font-size: 13px;
    color: #555555;
    border-radius: 4px;
    width: 100%;
    background-color: #fff;
    resize: none;

    &:focus{
        outline: 0;
        border: none;
    }
`;

export const ButtonActionMessage = styled.button`
    position: absolute;
    border: 0;
    padding: 9px 24px;
    border-radius: 30px;
    top: 40px;
    right: 32px;
    cursor: pointer;
    background-color: #007456;
    color: #fff;
    outline: 0;
    transition: all .3s ease 0s;
    font-size: 13px;
`;