import styled from "styled-components";

export const HeroWrapper = styled.div`
  background: #0b1d28 !important;
  position: relative;
  padding: 5rem 2rem 3rem;
  @media screen and (min-width: 1024px) {
    padding: 8rem 0 5rem;
  }
`;

export const CommonContainer = styled.div`
  display: flex;
  max-width: 960px;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const HeroDetail = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 620px;

  & > h1 {
    font-size: 58px;
    line-height: 72px;
    color: #fff;
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 1rem;
  }

  & > p {
    color: #979797 !important;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 3rem;
    font-size: 14px;

    & > svg {
      color: #088952 !important;
    }
  }

  @media screen and (min-width: 1024px) {
    & > h1 {
      font-size: 56px;
    }

    & > p {
      font-size: 14px;
    }
  }
`;

export const HeroButtonWrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin: 20px 0 3rem;
`;

export const HeroButton = styled.button`
  background: ${({ bg }) => bg};
  border: 1px solid ${({ color }) => color};
  border-radius: 8px;
  padding: 12px 24px;
  display: flex;
  align-self: flex-start;
  cursor: pointer;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    background: ${({ bghover }) => bghover};
    border-color: ${({ bgborder }) => bgborder};

    & > span {
      color: ${({ fonthovercolor }) => fonthovercolor};
    }
  }

  & > span {
    font-size: 13.5px;
    font-weight: 500;
    color: ${({ fontcolor }) => fontcolor ? fontcolor : "#fff"};
  }

  @media screen and (min-width: 1024px) {
    & > span {
      font-size: 16px;
    }
  }
`;