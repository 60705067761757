import styled from "styled-components";

export const JobDetailWrapper = styled.div`
  background-color: #f9f9f9;
  min-height: 100vh;
  padding-top: 4rem;
`;

export const JobDetailContainer = styled.div`
  padding: 8px 1rem;

  @media screen and (min-width: 1024px) {
    padding: 12px 6rem;
  }
`;

export const MainJobDetailWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const MainJobDetailContainer = styled.div`
  max-width: 1200px;
  flex: 1;
`;

export const MainJobDetailFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`;

export const MainJobDetailTop = styled.div`
  border: 1px solid #eee;
  border-radius: 16px;
  //flex: 0 0 auto;
  overflow: hidden;
  width: 100%;

  @media screen and (min-width: 1024px) {
    //max-width: calc(100% - 400px);
  }
`;

export const MainTopDetail = styled.div`
  padding: 32px;
  background: #fff;
`;

export const DetailHeaderTop = styled.div`
  display: flex;
`;

export const DetailHeaderTopLeft = styled.div`
  display: flex;
  width: 100%;
`;

export const DetailHeaderTopLeftTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;

  & > h1 {
    font-size: 21px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    font-weight: 700;
  }

  & > p, ul > li {
    color: #555555;
    font-weight: 400;
    line-height: 1.5;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
  }

  & > span {
    font-size: 13px;
  }

  @media screen and (min-width: 1024px) {
    & > h1 {
      font-size: 24px;
    }
  }
`;

export const FileNameSpan = styled.span`
  color: #0174BE;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 13px;

  &:hover {
    text-decoration: underline;
  }
`;

export const DetailHeaderWrap = styled.div`
  margin-bottom: 8px;

  & > h1 {
    font-size: 21px;
    color: #333;
    font-weight: 700;
    margin-bottom: 24px;
  }

  & > h2 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

export const PremiumWrapperHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 1rem;
`;

export const JobInsightContainer = styled.div`
  display: grid;
  gap: 1rem;

  @media screen and (min-width: 1024px) {
    margin-top: 12px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const Insight = styled.div`
  display: flex;
  gap: 1rem;
`;

export const InsightDetail = styled.div`
  display: flex;
  flex-direction: column;

  & > h2 {
    color: #111111;
    font-size: 14px;
    font-weight: 600;
  }

  & > p {
    font-size: 13px;
    margin-bottom: 0;
  }
`;

export const InsightAvatar = styled.div`
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 116, 86, 0.05);
  color: #007456;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Secondary = styled.div`
  margin: 0 -15px;

  @media screen and (min-width: 1024px) {
    margin: 0;
    flex: 0 0 400px;
    max-width: 400px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 70px;
  z-index: 2;
`;

export const JobApplySidebar = styled.div`
  background: rgba(0, 116, 86, 0.05);
  border: 1px solid rgba(0, 116, 86, 0.07);
  padding: 28px;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 1rem;
`;

export const JobApplyBottom = styled.div`
  display: none;
  background: rgba(0, 116, 86, 0.05);
  border: 1px solid rgba(0, 116, 86, 0.07);
  padding: 28px;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 1rem;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const ApplyInfo = styled.div`
  width: 100%;

  & > h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  & > p {
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 13px;
  }
`;

export const ApplyNow = styled.div`
  min-width: 270px;
  display: inline-block;
  background-color: #007456;
  border: solid 1px #007456;
  color: #fff;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  padding: 10px 26px;
  font-size: 13px;
  -webkit-border-radius: 3em;
  -moz-border-radius: 3em;
  border-radius: 3em;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  font-weight: 500;
  cursor: pointer;
  text-align: center;
`;

export const JobBottomApplyInterest = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
  }
`;

export const BidderContainer = styled.div``;

export const BidderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const NonGoogleAvatar = styled.div`
  width: ${({ width }) => (width ? width : "44px")};
  height: ${({ height }) => (height ? height : "44px")};
  border-radius: 30px;
  background: ${({ background }) => (background ? background : "#007456")};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize};
`;

export const Bidder = styled.div`
  display: flex;
  flex-direction: column;

  & > h3 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 1px;
  }

  & > span {
    font-size: 13px;
  }
`;