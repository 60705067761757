import Joi from "joi-browser";
import React, { Component } from "react";
import Input from "./input";
import InputFile from "./inputFile";
import Select from "./select";
import { FormButton } from "./style";
import TextArea from "./textArea";

class Form extends Component {
    state = {
        data: {},
        errors: {},
        files: [],
        objectURLs: [],
    };

    validate = () => {
        const options = { abortEarly: false }; // JOI terminates validation as soon as find error
        const { error } = Joi.validate(this.state.data, this.schema, options);
        if (!error) return null;

        const errors = {};

        for (let item of error.details) errors[item.path[0]] = item.message;

        return errors;
    };

    validateProperty = ({ name, value }) => {
        const obj = { [name]: value }; //computed properties
        const schema = { [name]: this.schema[name] };
        const { error } = Joi.validate(obj, schema);
        if (name === "phone_number" && error !== null) {
            if (error.details[0].message.includes("pattern")) {
                error.details[0].message =
                    "Input nomor handphone dimulai dengan +62 or 08";
            }
        }

        if (error && error.details[0].message.includes("not allowed")) {
            error.details[0].message = `Field ini harus diisi`
        } else if (error && error.details[0].message.includes("long")) {
            var errMessage = error.details[0].message.split(" ");
            error.details[0].message = `Minimal ${errMessage[7]} karakter`
        }
        return error ? error.details[0].message : null;
    };

    handleSubmit = (e) => {
        e.preventDefault(); //submitting form to server causes full page reload
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;

        this.doSubmit();
    };

    handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] =
            input.type === "number" ? parseInt(input.value, 10) : input.value;
        if (input.name === "confirm_password") {
            if (data[input.name] !== data["password"]) {
                errors[input.name] = "The password confirmation does not match";
            }
        }
        this.setState({ data, errors });
    };

    handleFileChange = (e) => {
        let files = [...this.state.files];
        let objectURLs = [...this.state.objectURLs];
        const data = { ...this.state.data };
        for (let i = 0; i < e.target.files.length; i++) {
            data[e.target.name] = URL.createObjectURL(e.target.files[i]);
            files.push(e.target.files[i]);
            objectURLs.push(URL.createObjectURL(e.target.files[i]));
        }

        this.setState({ files, objectURLs, data });
    };

    renderInput(name, label, placeholder = "", type = "text", paddingLeft) {
        const { data, errors } = this.state;
        return (
            <Input
                type={type}
                name={name}
                value={data[name] || ""}
                onChange={this.handleChange}
                label={label}
                error={errors[name]}
                placeholder={placeholder}
                paddingLeft={paddingLeft}
            />
        );
    }

    renderSelect(name, options, label) {
        const { data, errors } = this.state;

        return (
            <Select
                name={name}
                value={data[name]}
                onChange={this.handleChange}
                error={errors[name]}
                options={options}
                label={label}
            />
        );
    }

    renderTextArea(
        name,
        label,
        placeholder,
        height,
        disabled = false,
        isUsingLabel = true
    ) {
        const { data, errors } = this.state;
        return (
            <TextArea
                name={name}
                value={data[name]}
                onChange={this.handleChange}
                label={label}
                error={errors[name]}
                height={height}
                disabled={disabled}
                placeholder={placeholder}
                isUsingLabel={isUsingLabel}
            />
        );
    }

    renderButton(label, width) {
        return (
            <FormButton width={width} disabled={this.validate()}>
                {label}
            </FormButton>
        );
    }

    renderInputFile(
        name,
        label,
        type = "file",
        multiple,
        accept = "image/*",
        description
    ) {
        const { errors } = this.state;
        return (
            <InputFile
                type={type}
                name={name}
                onChange={this.handleFileChange}
                label={label}
                error={errors[name]}
                multiple={multiple}
                accept={accept}
                id={name}
                description={description}
            />
        );
    }
}

export default Form;
