import styled from "styled-components";

export const WithdrawWrapper = styled.div`
    margin-top: 1.5rem;
    & > h1 {
        font-size: 15px;
        font-weight: 700;
    }

    & > h2 {
        & > span {
            font-size: 18px;
        }
    }

    & > span {
        opacity: .7;
        font-size: 14px;
    }

    & > p {
        opacity: .7;
        font-size: 13px;
        margin-bottom: 8px;
    }

    & > .withdrawal {
        border: 1px solid #cdc7c2;
        border-radius: .25em;
        background: #fff;
        outline: none;
        width: 100%;
        padding: 13px;
        box-shadow: 0 3px 0 0 #e2e2e2;
        font-size: 13px;
    }

    & > h3 {
        margin-top: 8px;
        font-size: 15px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const WithdrawHeader = styled.div`
    & > h1 {
        font-size: 15px;
        font-weight: 700;
    }

    & > p {
        font-size: 13px;
        opacity: .7;
        margin-top: 4px;
        line-height: 1.5;
    }

    & > h2 {
        margin-bottom: 10px;
        font-size: 18px;
    }

    & > h3 {
        font-size: 15px;
        margin-top: 1rem;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const SelectionFlex = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    & > label {
        font-size: 13px;
    }
`;

export const SelectionRules = styled.ul`
    margin-top: 10px;
    padding-left: 1rem;
    & > li {
        font-size: 13px;
    }
`;

export const WithdrawSelection = styled.input`
    width: .7em;
    height: .7em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;

    &:checked {
        background-color: #0a5f99;
    }
`;

export const StatusWithdrawWrapper = styled.div`
    background-color: #FDF7DF;
    border: 1px solid #FEEC6F;
    color: #C9971C;
    border-radius: 1em;
    padding: 2px 10px;
    font-size: 13px;
`;

export const WithdrawInput = styled.input`
    border: 1px solid #cdc7c2;
    border-radius: .25em;
    background: #fff;
    outline: none;
    width: 100%;
    padding: 13px;
    box-shadow: 0 3px 0 0 #e2e2e2;
`;

export const WithdrawFooterFlex = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
`;

export const WithdrawFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > input {
        font-size: 13px;
    }
`;