import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import featured from "../../assets/icons/dashboard/table/icon-featured.svg";
import urgent from "../../assets/icons/dashboard/table/icon-urgent.svg";
import logo from '../../assets/images/logo.png';
import { getHustles } from "../../service/jobService";
import { timeSince } from "../../utils";
import {
    CardDaysLeft,
    CardFooter,
    CardFooterCenter,
    CardGrid,
    CardTopDetail,
    CardTopWrapper,
    CardWrapper,
    HowContainer,
    HowTitle,
    HowTitleWrapper,
    HowWrapper,
    MoreButton,
    PremiumWrapper
} from "./style";

const How = (props) => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        fetchProjects()
    }, []);

    const fetchProjects = async () => {
        const { status, data } = await getHustles({
            limit: 3,
        });
        if (status === 200) {
            setJobs(data.data);
        }
    }

    return (
        <HowWrapper>
            <HowContainer>
                <HowTitleWrapper>
                    <HowTitle>
                        <h1>Cari Hustlers atau Proyek Sampingan dengan Mudah dan Cepat!</h1>
                        <p>Pilih side hustlenya dan kirim lamaran terbaikmu untuk mendapatkan penghasilan tambahan</p>
                    </HowTitle>
                </HowTitleWrapper>
                <CardGrid>
                    {jobs.map((job, index) => <CardWrapper onClick={() => {
                        window.location = `/hustle/${job['uuid']}`
                    }} key={index}>
                        <CardTopWrapper>
                            <img src={logo} alt="" />
                            <PremiumWrapper>
                                {job['is_featured'] ? (<img src={featured} alt="" />) : null}
                                {job['is_urgent'] ? (<img src={urgent} alt="" />) : null}
                            </PremiumWrapper>
                        </CardTopWrapper>
                        <CardTopDetail>
                            <h1>{job.job_title}</h1>
                            <p>oleh <span>{job.created_by}</span> pada <span
                                style={{ color: "#007456" }}>{job.category}</span>
                            </p>
                            <span>{timeSince(new Date(job.created_at))}</span>
                        </CardTopDetail>
                        <CardFooter>
                            <span style={{
                                color: "#6c4cbe",
                                background: "#f5ecff",
                                padding: "5px 12px",
                                borderRadius: "50px",
                            }}>{job['project_bid'].includes("Penawaran") ? job['project_bid'] :
                                <CurrencyFormat value={job['project_bid']} displayType={'text'} thousandSeparator={true}
                                    prefix={'Rp. '} />}</span>
                            <span style={{
                                color: "#007456",
                                background: "rgba(0,116,86,.05)",
                                padding: "5px 12px",
                                borderRadius: "50px",
                            }}>{job.total_bidder} Pelamar</span>
                            <span style={{
                                color: "#007456",
                                background: "rgba(0,116,86,.05)",
                                padding: "5px 12px",
                                borderRadius: "50px",
                            }}>{job.job_status}
                            </span>
                        </CardFooter>
                        <CardDaysLeft>
                            <span>{job.job_deadline.substring(0, 2)}</span> {job.job_deadline.substring(2, job.job_deadline.length)} untuk
                            apply
                        </CardDaysLeft>
                    </CardWrapper>)}
                </CardGrid>
                <CardFooterCenter>
                    <MoreButton bg={"#088952"} color={"#088952"} bghover={"#06653c"} bgborder={"#055935"}
                        onClick={() => window.location = "/hustles"}>
                        <span>Lebih banyak</span>
                    </MoreButton>
                </CardFooterCenter>
            </HowContainer>
        </HowWrapper>);
}

export default How;