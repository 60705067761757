import styled from "styled-components";

export const BidWrapper = styled.div`
  border: 1px solid #ddd;
  display: flex;
  border-radius: 4px;
  align-items: center;
  overflow: hidden;

  & > .offer {
    border: 0;
    padding: 10.5px 16px;
    width: 100%;
    font-size: 13px;

    &:focus {
      outline: none;
      border: 0;
    }
  }
`;

export const IDRWrapper = styled.div`
  border-right: 1px solid #ddd;
  padding: 10.5px 16px;
  font-size: 13px;
`;

export const IDRInput = styled.input`
  border: 0;
  padding: 10.5px 16px;
  width: 100%;

  &:focus {
    outline: none;
    border: 0;
  }
`;

export const BidDetailWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const BidDetailContainer = styled.div`
  width: 65%;
`;


export const BidBlockWrapper = styled.div`
  padding: 0;
  border-radius: 8px;
  border: 1px solid var(--civi-color-border);
  background-color: #fff;
  margin: 1rem 0;

  & > h1 {
    font-size: 14px;
    color: #111;
  }

  & > span {
    font-size: 12px;
    color: #111;
    font-family: 'Inter', sans-serif;
  }
`;

export const BidDetail = styled.div`
  border-bottom: 1px solid #eee;
  padding: 4px 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;

  & > h1 {
    font-size: 13px;
  }

  & > span {
    font-size: 13px;
    text-align: right;
  }
`;