import { createGlobalStyle } from "styled-components";

import CircularStdBlackWoff from "../assets/fonts/circular/CircularStd-Black.woff";
import CircularStdBlackWoff2 from "../assets/fonts/circular/CircularStd-Black.woff2";
import CircularStdBoldWoff from "../assets/fonts/circular/CircularStd-Bold.woff";
import CircularStdBoldWoff2 from "../assets/fonts/circular/CircularStd-Bold.woff2";
import CircularStdBookWoff from "../assets/fonts/circular/CircularStd-Book.woff";
import CircularStdBookWoff2 from "../assets/fonts/circular/CircularStd-Book.woff2";
import CircularStdMediumWoff from "../assets/fonts/circular/CircularStd-Medium.woff";
import CircularStdMediumWoff2 from "../assets/fonts/circular/CircularStd-Medium.woff2";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'CircularStd';
    src: url('${CircularStdBookWoff2}') format('woff2'),
    url('${CircularStdBookWoff}') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'CircularStd';
    src: url('${CircularStdMediumWoff2}') format('woff2'),
    url('${CircularStdMediumWoff}') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'CircularStd';
    src: url('${CircularStdBlackWoff2}') format('woff2'),
    url('${CircularStdBlackWoff}') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'CircularStd';
    src: url('${CircularStdBoldWoff2}') format('woff2'),
    url('${CircularStdBoldWoff}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }


  * {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
    color: #4a4a4a;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  p {
    line-height: 1.6;
    color: #4a4a4a;
  }

  span {
    color: #4a4a4a;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  table {
    background: #fff;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #eee;
    text-align: left;
  }

  thead th {
    padding-top: 18px;
    padding-bottom: 18px;
    font-weight: 600;
    font-size: 14px;
    border: 0;
    padding-left: 24px;
  }

  tr {
    width: 100%;
  }

  thead tr {
    border-bottom: 1px solid #eee;
  }

  td {
    font-size: 14px;
    padding: 16px 0 16px 24px;
    border-bottom: 1px solid #eee;
  }
`;