import { ContentState, EditorState, convertFromHTML } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { draftToHTML } from "../../../utils";
import { RichTextWrapper } from "./style";

const RichText = ({ setContent, content }) => {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const setDefaultContent = () => {
        if (content) {
            const editor = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(content)
                )
            )
            setEditorState(editor);
        }
    }

    useEffect(() => {
        setDefaultContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content])

    useEffect(() => {
    }, [editorState]);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML(state);
    };
    const convertContentToHTML = (editorState) => {
        let currentContentAsHTML = draftToHTML(editorState.getCurrentContent());
        setContent(currentContentAsHTML);
    };

    const config = {
        options: [
            "inline",
            "blockType",
            "list",
            "textAlign",
            "history",
            "image",
            "emoji",
            "link",
        ],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
    };

    return (
        <RichTextWrapper>
            <Editor
                toolbar={config}
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                editorClassName="draft-wrapper"
                toolbarClassName="draft-wrapper"
            />
        </RichTextWrapper>
    );
};

export default RichText;
