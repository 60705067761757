export const CategoriesJob = [

    {
        name: "Semua",
        value: "Semua"
    },
    {
        name: "Web dan Pemrograman",
        value: "Web & Pemrograman"
    },
    {
        name: "Desain Grafis, Interior dan Branding",
        value: "Desain Grafis, Interior & Branding"
    },
    {
        name: "Pemasaran dan Periklanan",
        value: "Pemasaran & Periklanan"
    },
    {
        name: "Penulisan dan Penerjemahan",
        value: "Penulisan & Penerjemahan"
    },
    {
        name: "Admin dan Customer Service",
        value: "Admin & Customer Service"
    },
    {
        name: "Mengajar dan Joki",
        value: "Mengajar & Joki"
    },
    {
        name: "Data dan Analisis",
        value: "Data & Analisis"
    }
]