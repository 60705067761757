import jwtDecode from "jwt-decode";
import httpService from "./httpService";

export async function register(body) {
    return httpService.post("/register", body);
}

export async function login(body) {
    return httpService.post("/login", body);
}

export async function activation(email, otp) {
    return httpService.get(`activate?email=${email}&otp=${otp}`)
}

export function getCurrentUser() {
    try {
        const token = localStorage.getItem("token");
        return jwtDecode(token);
    } catch (e) {
        return null;
    }
}

export function getToken() {
    return localStorage.getItem("token");
}

export function getAuthHeaders() {
    return {
        headers: {
            Authorization: "Bearer " + getToken(),
        },
    };
}

export async function logOut() {
    return httpService.delete("/api/logout", getAuthHeaders());
}

export function getAuthHeadersWithMultipart(bucketpath, fileName = "") {
    return {
        headers: {
            Authorization: "Bearer " + getToken(),
            "content-type": "multipart/form-data",
            "X-BucketPath": bucketpath,
            "X-FileName": fileName
        },
    };
}
