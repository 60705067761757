import styled from "styled-components";

export const CommonWrapper = styled.div`
  padding: 8px 2rem;

  & > h1 {
    font-size: 21px;
  }

  @media screen and (min-width: 1024px) {
    padding: 12px 6rem;
  }
`;

export const TncWrapper = styled.div`
  margin-top: 2rem;

  & > h1 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  ul {
    font-size: 13px;
  }

  & > p, ul > li > p {
    color: #444;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 8px;
    text-align: justify;
  }

  ul > li > h1 {
    font-size: 13px;
    margin-bottom: 8px;
  }

  ul > li {
    & > ol > li > p {
      color: #444;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 8px;
      text-align: justify;
    }
  }
`;