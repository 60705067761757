import styled from "styled-components";

export const AuthWrapper = styled.section`
  min-height: 100vh;
  padding: 0 2rem;

  @media screen and (min-width: 1024px) {
    padding: 0;
  }
`;

export const AuthContainer = styled.div`
  display: flex;
`;

export const AuthImage = styled.div`
  display: none;

  & > img {
    width: 100%;
    min-height: 100vh;
    object-fit: cover;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
`;

export const AuthFormWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

export const AuthFormFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 1.75rem;
`;

export const AuthFormContainer = styled.div`

  & > h1 {
    font-size: 21px;
    font-weight: 700;
    color: #333;
  }

  @media screen and (min-width: 1024px) {
    width: 66.66667%;
    flex: 0 0 66.66667%;
  }
`;