import styled from "styled-components";

export const FormBlockWrapper = styled.div`
  padding: 24px 32px;
  border-radius: 8px;
  border: 1px solid var(--civi-color-border);
  background-color: #fff;
  margin-bottom: 1rem;

  & > h1 {
    font-size: 18px;
    color: #111;
    font-weight: 600;
  }
`;

export const FormFlexContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  margin-bottom: 21px;
`;

export const FormLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    font-size: 13px;
    margin-bottom: 8px;
  }
`;


export const ServicePremWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 8px 0;

  & > label > span {
    color: red;
  }

  & > label {
    font-size: 13px;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;