import React from 'react';
import {ModalWrapper} from "../auth/style";
import ApplyJobForm from "../../../form/applyJob";

const ApplyJobModal = ({projectId}) => (
    <ModalWrapper>
        <h1 style={{marginBottom: "0", textAlign: "center", fontSize: "21px", color: "#111"}}>Lamar Side Hustle</h1>
        <ApplyJobForm id={projectId}/>
    </ModalWrapper>
);

export default ApplyJobModal;