import React from 'react';
import {ModalWrapper} from "./style";
import AuthForm from "../../../form/auth";

const AuthBody = (props) => (
    <ModalWrapper>
        <h1>Welcome Back 👋</h1>
        <AuthForm/>
    </ModalWrapper>
);

export default AuthBody;