import React from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../../service/authService";
import { ButtonDashboard, DashboardContent, DashboardFlex, DashboardFlexMenu, DashboardNavbar } from "../style";

const user = getCurrentUser();

export const DashboardClientWrapper = ({ child }) => {

    const navigate = useNavigate();
    return (
        <DashboardContent>
            <DashboardNavbar>
                <DashboardFlex>
                    <DashboardFlexMenu>
                        <h1>Hello, {user['username']}</h1>
                        <ButtonDashboard onClick={() => navigate("/dashboard/client/hustles/new")}>
                            <span>Kirim Pekerjaan</span>
                        </ButtonDashboard>
                    </DashboardFlexMenu>
                </DashboardFlex>
            </DashboardNavbar>
            {child}
        </DashboardContent>
    )
}