import React, { useState } from 'react';
import iconDashboard from '../../assets/icons/dashboard.svg';
import iconLogout from '../../assets/icons/logout.svg';
import logo from '../../assets/images/logotext.png';
import { ModalComponent } from "../../components/modal";
import AuthBody from "../../components/modal/auth";
import { getCurrentUser, logOut } from "../../service/authService";
import {
    ListNavDashboard,
    NavbarAuthContainer,
    NavbarContainer,
    NavbarDropdown,
    NavbarDropdownContainer,
    NavbarFlexWrapper,
    NavbarMenu,
    NavbarWrapper
} from "./style";

const user = getCurrentUser();

const Navbar = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHoverMenu, setIsHoverMenu] = useState(false);

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const hoverMenu = () => {
        setIsHoverMenu(!isHoverMenu);
    }
    return (
        <NavbarWrapper>
            <NavbarContainer>
                <NavbarFlexWrapper>
                    <a href="/"><img src={logo} alt="logo" /></a>
                </NavbarFlexWrapper>
                <NavbarMenu>
                    <span onClick={() => window.location = "/hustles"}>Side Hustles</span>
                    <span>Tentang Kami</span>
                </NavbarMenu>
                <NavbarFlexWrapper>
                    {user ? <NavbarAuthContainer onMouseEnter={() => hoverMenu()}
                        onMouseLeave={() => hoverMenu()}>
                        <span>Hi, {user['username']}</span>
                        <NavbarDropdown isOpen={isHoverMenu}>
                            <NavbarDropdownContainer>
                                <ListNavDashboard>
                                    <li onClick={() => {
                                        const isClient = user['is_client'];
                                        if (isClient) {
                                            window.location = "/dashboard/client/hustles"
                                        } else {
                                            window.location = "/dashboard/hustler/hustles"
                                        }
                                    }
                                    }><span><img src={iconDashboard}
                                        alt="" /></span><span>Dashboard</span>
                                    </li>
                                    <li onClick={async () => {
                                        const { status } = await logOut();
                                        if (status === 200) {
                                            localStorage.removeItem("token");
                                            setTimeout(function () {
                                                window.location = "/"
                                            }, 500)
                                        }
                                    }}><span><img src={iconLogout} alt="" /></span><span>Logout</span></li>
                                </ListNavDashboard>
                            </NavbarDropdownContainer>
                        </NavbarDropdown>
                    </NavbarAuthContainer> : <>
                        <span onClick={openModal}>Masuk</span>
                        <span onClick={() => window.location = "/register"}>Gabung</span></>}
                </NavbarFlexWrapper>
            </NavbarContainer>
            <ModalComponent
                show={isModalOpen} onHide={openModal} size="m" body={<AuthBody />}
            />
        </NavbarWrapper>);
}

export default Navbar;