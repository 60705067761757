import styled from "styled-components";

export const FooterWrapper = styled.footer`
  background: #102a39;
  padding: 2rem;

  @media screen and (min-width: 1024px) {
    padding: 2rem 10rem 1rem;
  }
`;

export const FooterContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

export const FooterFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding-bottom: 3rem;
  }
`;

export const FooterFirst = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  & > img {
    width: 178px;
  }

  & > p {
    margin-top: 20px;
    color: hsla(0, 0%, 60%, .7);
    font-size: 14px;
    padding-right: 7.5rem;
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    margin-bottom: 0;

    & > p {
      margin-top: 40px;
    }
  }
`;

export const FooterSplitter = styled.div`
  display: flex;
`;

export const FooterHalf = styled.div`
  flex: 0 0 50%;
  max-width: 50%;

  & > h1 {
    font-size: 18px;
    color: #fff;
    margin-bottom: .5rem;
    font-weight: 500;
  }
`;

export const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  & > li {
    margin-top: 1rem;
    transition: .3s ease;

    &:hover {
      margin-left: 2px;
    }
  }

  & > li > a {
    font-size: 14px;
    text-decoration: none;
    color: hsla(0, 0%, 60%, .7);
  }
`;

export const FooterBottom = styled.div`
  border-top: 1px solid hsla(0, 0%, 87.1%, .2);
  padding: 1rem 0;

  & > p {
    color: hsla(0, 0%, 60%, .7);
    font-size: 14px;
    text-align: center;
    margin: 0;
  }

  @media screen and (min-width: 1024px) {
    padding: 1.75rem 0;
  }
`;