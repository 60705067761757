import React from 'react';
import ReactPaginate from "react-paginate";

const Pagination = ({itemsCount, pageSize, onPageChange}) => {
    const pageCount = Math.ceil(itemsCount / pageSize);

    return (
        <ReactPaginate
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(data) => onPageChange(data.selected + 1)}
            breakLabel="..."
            activeClassName="page-item active"
            activeLinkClassName="page-link"
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            previousClassName="page-item"
            nextLinkClassName="page-link"
            nextClassName="page-item"
            breakLinkClassName="page-link"
            breakClassName="page-item"
            previousLabel="prev"
            nextLabel="next"
        />
    )
}

export default Pagination;