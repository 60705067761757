import React, { useState } from "react";
import * as Lu from 'react-icons/lu';
import { toast } from "react-toastify";
import { ModalWrapper } from "../../components/modal/auth/style";
import { WithdrawFlex, WithdrawHeader } from "../../components/modal/withdraw/style";
import { SubmitButton } from "../../page/dashboardHustlers/profile/profileBody/style";
import { uploadImage } from "../../service/gcloudservice";
import { updateApplicant } from "../../service/jobService";

export const FinishHustle = ({ id, title }) => {
    const [files, setFiles] = useState([]);
    const [objectURLs, setObjectURLs] = useState([]);
    const [data, setData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const onUploadFile = ({ currentTarget: input }) => {
        setFiles(files.pop());
        setObjectURLs(objectURLs.pop());
        let currFiles = [...files];
        let currObjectURLs = [...objectURLs];
        const currData = { ...data };
        for (let i = 0; i < input.files.length; i++) {
            currData[input.name] = URL.createObjectURL(input.files[i]);
            currFiles.push(input.files[i]);
            currObjectURLs.push(URL.createObjectURL(input.files[i]));
        }

        setFiles(currFiles);
        setObjectURLs(currObjectURLs);
        setData(currData);
    }

    const onSubmit = async () => {
        if (!isSubmit) {
            setIsSubmit(true);
            let urls = [];
            for (const file of files) {
                const { status, data } = await uploadImage(file, "project/final");
                if (status === 200) {
                    const fileURL = data.data["pathname"];
                    urls.push(fileURL);
                }
            }
            try {
                const { status } = await updateApplicant({
                    project_apply_id: id,
                    file_url: urls,
                    status: 3
                })
                if (status === 200) {
                    toast.success("Berhasil submit file");
                    setTimeout(function () {
                        window.location = "/dashboard/hustler/hustles"
                    }, 1000)
                }
            } catch (e) {

            }
        } else {
            toast.warning("Sedang mengunggah file kerjaanmu ...")
        }
    }

    return (
        <ModalWrapper>
            <WithdrawHeader>
                <h2>{title}</h2>
                <p>Mengirimkan file artinya kamu sudah selesai mengerjakan dan setuju bahwa klien akan melakukan proses review terhadap pekerjaanmu. Pastikan status pembayaran klien adalah sudah terbayar penuh.</p>
                <WithdrawFlex>
                    <input accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*" type="file" multiple={true} id="final" onChange={onUploadFile} />
                    <SubmitButton onClick={onSubmit}>
                        <Lu.LuArrowUpFromLine /> Upload
                    </SubmitButton>
                </WithdrawFlex>
            </WithdrawHeader>
        </ModalWrapper>
    )
}